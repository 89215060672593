import React from "react";
import ReactDOM from "react-dom/client";
// external
import { BrowserRouter, Routes, Route } from "react-router-dom";

// internal
import App from "./App";
import { UserProvider } from "./context/userContext";

// manage
//  inst
import MInst from "./pages/minst";
import MIHome from "./pages/home";
import PageNotFound from "./pages/404";
//  finance
import MgInstFinanceHome from "./pages/finance/home";
import MFIncome from "./pages/income";
import MFPayroll from "./pages/payroll";
import MFExpenses from "./pages/expenses";
import MFFees from "./pages/fees/index";
import IFFeesTotal from "./pages/fees/total";
import IFFeesTable from "./pages/fees/fees";
import IFFeesForm from "./pages/fees/student";
import IFFeesPay from "./pages/fees/pay";

const MEdIFFeeStructure = React.lazy(() => import("./pages/fee_structure"));
const MFBudget = React.lazy(() => import("./pages/budget"));
const Start = React.lazy(() => import("./pages/_start"));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          <Route path="/" element={<MInst />}>
            <Route path="" element={<MIHome />} />
            <Route path="*" element={<PageNotFound />} />
            <Route path="home" element={<MgInstFinanceHome />} />
            <Route path="income" element={<MFIncome />} />
            <Route path="expenses" element={<MFExpenses />} />
            <Route path="payroll" element={<MFPayroll />} />
            <Route path="fees/" element={<MFFees />}>
              <Route path="total" element={<IFFeesTotal />} />
              <Route path="fees" element={<IFFeesTable />} />
              <Route path="student" element={<IFFeesForm />} />
              <Route path="pay" element={<IFFeesPay />} />
            </Route>

            <Route
              path="feestructure"
              element={
                <React.Suspense fallback={<></>}>
                  <MEdIFFeeStructure />
                </React.Suspense>
              }
            />
            <Route
              path="budget"
              element={
                <React.Suspense fallback={<></>}>
                  <MFBudget />
                </React.Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="start"
          element={
            <React.Suspense fallback={<></>}>
              <Start />
            </React.Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  </UserProvider>
);
