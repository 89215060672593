import { createContext, useState } from "react";

const initialFeeStructure = [];
const initialPrograms = [];

export const FeeStructureContext = createContext(initialFeeStructure);

export const FeeStructureProvider = ({ children }) => {
  const [feeStructureState, setFeeStructureState] = useState(initialFeeStructure);
  const [programState, setProgramState] = useState(initialPrograms);

  const setFeeStructure = (newFee) => {
    setFeeStructureState(newFee);
  };

  const addFeeStructure = (newFee) => {
    setFeeStructureState((prevFeeStructure) => [newFee, ...prevFeeStructure]);
  };
  
  const updateFeeStructure = (feeId, updatedfacility) => {
    setFeeStructureState((prevFeeStructure) =>
      prevFeeStructure.map((facility) =>
        facility.id === feeId ? { ...facility, ...updatedfacility } : facility
      )
    );
  };

  const deleteFeeStructure = (feeId) => {
    setFeeStructureState((prevFeeStructure) =>
      prevFeeStructure.filter((fee) => fee.id !== feeId)
    );
  };

  const setPrograms = (newProgram) => {
    setProgramState(newProgram);
  };


  return (
    <FeeStructureContext.Provider
      value={{
        feeStructureState,
        
        programState,

        setFeeStructure,
        addFeeStructure,
        updateFeeStructure,
        deleteFeeStructure,

        setPrograms,
      }}
    >
      {children}
    </FeeStructureContext.Provider>
  );
};
