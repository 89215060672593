import { MdAttachMoney, MdOutlineMoney, MdOutlineSettings } from "react-icons/md";
import { TbReportMoney } from "react-icons/tb";
import { BiHome } from "react-icons/bi";
import { FaRegMoneyBillAlt } from "react-icons/fa";
import { GiTakeMyMoney, GiPayMoney, GiMoneyStack } from "react-icons/gi";

export default function Icons({ name, style }) {
  var icon = 0;
  switch (name) {
    case "MEducation":
      icon = (
        <span
          style={{ fontSize: "14px", fontWeight: "800" }}
          className="prime-color"
        >
          ME
        </span>
      );
      break;
    case "MPay":
      icon = (
        <span
          style={{ fontSize: "14px", fontWeight: "800" }}
          className="prime-color"
        >
          MP
        </span>
      );
      break;

    case "Home":
      icon = <BiHome style={style} />;
      break;

    case "Income":
      icon = <GiTakeMyMoney style={style} />;
      break;
    case "Expenses":
      icon = <GiPayMoney style={style} />;
      break;

    case "Payroll":
      icon = <GiMoneyStack style={style} />;
      break;

    case "Salary":
      icon = <GiMoneyStack style={style} />;
      break;

    case "Fees":
      icon = <MdAttachMoney style={style} />;
      break;

    case "Finance":
      icon = <FaRegMoneyBillAlt style={style} />;
      break;

    case "Setting":
      icon = <MdOutlineSettings style={style} />;
      break;

    // ____

    case "Budget":
      icon = <TbReportMoney style={style} />;
      break;

    case "Fee Structure":
      icon = <MdOutlineMoney style={style} />;
      break;

    default:
      break;
  }

  return <>{icon}</>;
}
