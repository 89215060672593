import { getData } from "./useFetch";

export const getCustomData = async (api,mainQuery,extraQuery) => { 
    
    const columnValuesGallary = mainQuery;
    
    const response = await getData(api, {
      ...columnValuesGallary,
      extraQuery: extraQuery,
    });
    return response;

}