// external
import { Link } from "react-router-dom";
import { Button } from "@mui/material/";
// internal
import { useState, useEffect, useContext } from "react";
import { SystemContext } from "../context/systemContext";
import { ExpensesContext } from "../context/expensesContext";
import { PayrollContext } from "../context/payrollContext";
import { FeesContext } from "../context/feesContext";
import numberFormate from "../utils/numberFormate";
import ManageContHead from "../component/manage/manageconthead";
import EmptyCard from "../component/emptyCard";
// import { UpdatesSm } from '../component/update/update';

function MIHome() {
  // get page data
  const { expensesState } = useContext(ExpensesContext);
  const { payrollState } = useContext(PayrollContext);
  const { feesState } = useContext(FeesContext);
  const [totalExpense, setTotalExpenses] = useState(0);
  const [totalPayroll, setTotalPayroll] = useState(0);
  const [totalFees, setTotalFees] = useState(0);

  useEffect(() => {
    const totalExpensesCost = expensesState.reduce((accumulator, row) => {
      return accumulator + parseFloat(row.cost);
    }, 0);
    setTotalExpenses(totalExpensesCost);

    const totalPayrollSalary = payrollState.reduce((accumulator, row) => {
      return accumulator + parseFloat(row.salary);
    }, 0);
    setTotalPayroll(totalPayrollSalary);

    const totalFeesAmount = feesState.reduce((accumulator, row) => {
      return accumulator + parseFloat(row.amount);
    }, 0);
    setTotalFees(totalFeesAmount);
  }, [expensesState]);

  const stats = [
    {
      data: "Rs." + numberFormate(totalFees - (totalExpense + totalPayroll)),
      title: "Income",
    },
    {
      data: "Rs." + numberFormate(totalExpense),
      title: "Expenses",
    },
    {
      data: "Rs." + numberFormate(totalPayroll),
      title: "Payroll",
    },
    {
      data: "Rs." + numberFormate(totalFees),
      title: "Fees",
    },
  ];

  return (
    <div className="mg-cont-m">
      <ManageContHead title="Home" />

      <section className="py-4 md:py-14">
        <div className="max-w-screen-xl mx-auto px-4 md:px-8">
          <ul className="items-center text-center justify-center gap-x-12 gap-y-10 md:flex md:flex-row md:flex-wrap md:gap-x-24">
            {stats.map((item, idx) => (
              <li key={idx} className="text-center inline-block m-2 p-2">
                <h4 className="text-xl md:text-4xl text-gray-600 font-semibold">
                  {item.data}
                </h4>
                <p className="mt-3 text-gray-600 font-medium">{item.title}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <div className="masonry-grid masonry-grid-count-2">
        <div className="masonry-grid__item border">
          <div className="m-card-header">
            <h1>Expenses</h1>
          </div>
          <div className="p-3">
            <div className="masonry-grid masonry-grid-count-2">
              {Object.values(expensesState)
                .slice(0, 4)
                .map((e, index) => (
                  <div className="masonry-grid__item border rounded-lg py-2 px-3">
                    <div className="flex items-center">
                      <div className="flex-1 truncate" title={e.subject}>
                        {e.subject}
                      </div>
                      <div className="flex-1 text-right text-sm">
                        Rs.{numberFormate(e.cost)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {Object.values(expensesState).length <= 0 && (
              <>
                <EmptyCard />
              </>
            )}
          </div>
          <div className="m-card-footer">
            <Link to="./expenses">
              <Button className="secondary-btn" size="small" type="outlined">
                More
              </Button>
            </Link>
          </div>
        </div>

        <div className="masonry-grid__item border">
          <div className="m-card-header">
            <h1>Payroll</h1>
          </div>
          <div className="p-3">
            <div className="masonry-grid masonry-grid-count-2">
              {Object.values(payrollState)
                .slice(0, 4)
                .map((e, index) => (
                  <div className="masonry-grid__item border rounded-lg py-2 px-3">
                    <div className="flex items-center">
                      <div className="flex-1">{e.account}</div>
                      <div className="flex-1 text-right text-sm">
                        Rs.{numberFormate(e.salary)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {Object.values(payrollState).length <= 0 && (
              <>
                <EmptyCard />
              </>
            )}
          </div>
          <div className="m-card-footer">
            <Link to="./payroll">
              <Button className="secondary-btn" size="small" type="outlined">
                More
              </Button>
            </Link>
          </div>
        </div>

        <div className="masonry-grid__item border">
          <div className="m-card-header">
            <h1>Fees</h1>
          </div>
          <div className="p-3">
            <div className="masonry-grid masonry-grid-count-2">
              {Object.values(feesState)
                .slice(0, 4)
                .map((e, index) => (
                  <div className="masonry-grid__item border rounded-lg py-2 px-3">
                    <div className="flex items-center">
                      <div className="flex-1">{e.service}</div>
                      <div className="flex-1 text-right text-sm">
                        Rs.{numberFormate(e.amount)}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
            {Object.values(feesState).length <= 0 && (
              <>
                <EmptyCard />
              </>
            )}
          </div>
          <div className="m-card-footer">
            <Link to="./fees">
              <Button className="secondary-btn" size="small" type="outlined">
                More
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MIHome;
