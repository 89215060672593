// external
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// internal
import * as React from "react";
import TotalTb, { TotalTbGraph } from "../../../component/tables/totaltb";
import { CardTwoCol } from "../../../component/cards/cards";
import { FeesContext } from "../../../context/feesContext";
import BarChart from "./components/barChart";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

export default function IFFeesTotal() {
  const { feesState } = React.useContext(FeesContext);

  function calculateTotalCostByAccount(array, account) {
    let totalCost = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].service === account) {
        totalCost += parseInt(array[i].amount);
      }
    }
    return totalCost;
  }

  const tableRows = [
    {
      title: "Tuition",
      amount: calculateTotalCostByAccount(feesState, "Tuition"),
    },
    {
      title: "Admission",
      amount: calculateTotalCostByAccount(feesState, "Admission"),
    },
    {
      title: "Service",
      amount: calculateTotalCostByAccount(feesState, "Service"),
    },
    {
      title: "Sports",
      amount: calculateTotalCostByAccount(feesState, "Sports"),
    },
    {
      title: "Health",
      amount: calculateTotalCostByAccount(feesState, "Health"),
    },
    {
      title: "Other",
      amount: calculateTotalCostByAccount(feesState, "Other"),
    },
  ];

  return (
    <div className="mg-cont-m">
      <CardTwoCol
        head={"Total Fees"}
        table={
          <TotalTb lefthead={"Service"} righthead={"Fees"} rows={tableRows} />
        }
        graph={<TotalTbGraph head={"Total Fees"} rows={tableRows} />}
      />
      {/* <BarChart
        tableRows={tableRows?.sort((a, b) => b.amount - a.amount)}
        feesState={feesState}
      /> */}
    </div>
  );
}
