import { createContext, useState } from "react";

const initialSd = [];

export const SdContext = createContext(initialSd);

export const SdProvider = ({ children }) => {
  const [sdState, setSdState] = useState(initialSd);
  
  const setSd = (newSd) => {
    setSdState(newSd);
  };

  return (
    <SdContext.Provider
      value={{
        sdState,

        setSd,
      }}
    >
      {children}
    </SdContext.Provider>
  );
};
