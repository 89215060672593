// external 
import Container from 'react-bootstrap/Container';

// internal
import * as React from 'react';
import '../style/root/index.css';
import FaceCont from '../component/root/faceCont';

function PageNotFound() {
  
  const action_btns = [
  ]

  return (
    <>
      <Container className='home-container'>

        <FaceCont 
          text={{main:"Page Not Found",sub:"The page you looking for doesn't exist on this url, please use any of the following links."}}
          img={"https://img.freepik.com/free-vector/page-found-concept-illustration_114360-1869.jpg?w=826&t=st=1675665511~exp=1675666111~hmac=4733756600f6d882d9dad1fd4f10e77fdbdb778c686cfeae71372dff356ff9ea"}
          btns={action_btns}
        />

      </Container>
    </>
  );
};

export default PageNotFound;