export default function BeforeLoad() {
  return (
    <>
      <div className="fixed z-50 top-0 left-0 w-full h-full bg-gray-50">
        <div class="flex items-center justify-center h-screen">
          <h1 class=" text-4xl text-center font-black prime-color md:text-5xl">
            iFinance
          </h1>
        </div>
      </div>
    </>
  );
}
