export default function EmptyCard() {
  return (
    <>
      <div className="flex items-center justify-center">
        <div className="py-2 rounded-lg">
          <img src={"../../empty.svg"} alt="Empty SVG" className="w-96" />
        </div>
      </div>
    </>
  );
}
