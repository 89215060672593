import React, { useContext, useEffect, useState } from "react";
// external
import { Outlet, useNavigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
// internal
import "./style/add/add.css";
import "./style/style.css";
import ScrollToTop from "./component/scrollToTop";
import { getData } from "./hooks/useFetch";
import { getCustomData } from "./hooks/getCustomData";
import {
  ME_INST_FEES,
  ME_INST_INST,
  ME_SD_SD,
  ME_STAFF,
  MF_EXPENSES,
  MF_FEES,
  MF_PAYROLL,
  MSUBSCRIPTIONS,
  M_MA,
} from "./config/api";
import { idDecrypt, idEncrypt } from "./hooks/useEncryption";
import { ExpensesContext } from "./context/expensesContext";
import { UserContext } from "./context/userContext";
import { FeesContext } from "./context/feesContext";
import { PayrollContext } from "./context/payrollContext";
import { SystemContext } from "./context/systemContext";
import { StaffContext } from "./context/staffContext";
import { SdContext } from "./context/sdContext";
import { setCookie } from "./hooks/useCookie";
import NotActive from "./component/notActive";
import { FeeStructureContext } from "./context/feeStructureContext";
import BeforeLoad from "./component/beforeload";
import { StatsContext } from "./context/statsContext";

function App() {
  const { setSystem } = useContext(SystemContext);
  const { startSession } = useContext(UserContext);
  const { setExpenses } = useContext(ExpensesContext);
  const { setFees } = useContext(FeesContext);
  const { setPayroll } = useContext(PayrollContext);
  const { setStaff } = useContext(StaffContext);
  const { setSd } = useContext(SdContext);
  const { setFeeStructure } = useContext(FeeStructureContext);
  const { setFeesTotal } = useContext(StatsContext);
  const [subscription, setSubscription] = useState("");
  const [beforeLoadStatus, setBeforeLoadStatus] = useState(true);
  const navigate = useNavigate();

  // get inst
  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(() => {
    // setCookie("mid", idEncrypt("1"), 30);
    // setCookie("minst_id", idEncrypt(37499), 30);

    setCookie("mid", idEncrypt("6"), 30);
    setCookie("minst_id", idEncrypt(719969), 30);
    setCookie("session", idEncrypt(18017), 30);

    Promise.all([
      getData(ME_INST_INST, {
        column: "minst_id",
        value: idDecrypt(getCookie("minst_id")),
      }),
      getCustomData(
        MSUBSCRIPTIONS + "/custom",
        { system_id: idDecrypt(getCookie("minst_id")) },
        "AND mp='iFinance' ORDER BY id"
      ),
      getData(M_MA, {
        column: "id",
        value: idDecrypt(getCookie("mid")),
      }),
    ])
      .then(([sys, subs, ma]) => {
        if (subs.length > 0) {
          const sObj = subs[0];
          if (sObj["status"] === "paid") {
            setSubscription(true);
          } else {
            setSubscription(false);
          }
        } else {
          navigate("../start");
        }
        if (sys.length > 0) {
          const sysObj = sys[0];
          setSystem(sysObj);
          startSession(idDecrypt(getCookie("mid")), sysObj, subs, ma[0]);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    Promise.all([
      getCustomData(
        MF_EXPENSES + "/custom",
        { mf_employer: idDecrypt(getCookie("minst_id")) },
        " ORDER By id "
      ),
      getCustomData(
        MF_PAYROLL + "/custom",
        { mf_employer: idDecrypt(getCookie("minst_id")) },
        " ORDER By id "
      ),
      // getCustomData(
      //   MF_FEES + "/custom",
      //   { mf_receiver: idDecrypt(getCookie("minst_id")) },
      //   " ORDER By id "
      // ),
      getData(MF_FEES + "/all", {
        fields:
          "id,mf_receiver,mf_payer,session,status,service,amount,term,time,date",
        column: "mf_receiver",
        value: idDecrypt(getCookie("minst_id")),
        queryFurther:
          " AND fees.session=" +
          idDecrypt(getCookie("session")) +
          " ORDER BY id DESC",
        page: 1,
        pageSize: 5,
      }),
      getCustomData(
        ME_STAFF + "/custom",
        { minst_id: idDecrypt(getCookie("minst_id")) },
        " ORDER By id "
      ),
      getCustomData(
        ME_SD_SD + "/custom",
        { minst_id: idDecrypt(getCookie("minst_id")) },
        " ORDER By id "
      ),
      getData(MF_FEES + "/total", {
        mf_receiver: idDecrypt(getCookie("minst_id")),
        session: idDecrypt(getCookie("session")),
      }),
    ])
      .then(([exp, payrol, fee, staff, sd, feesTotal]) => {
        setExpenses(exp.reverse());
        setPayroll(payrol.reverse());
        setFees(fee.reverse());
        setStaff(staff);
        setFeesTotal(feesTotal[0]?.total);
        setSd(sd);
        setTimeout(() => {
          setBeforeLoadStatus(false);
        }, 500);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });

    getCustomData(
      ME_INST_FEES + "/custom",
      { minst_id: idDecrypt(getCookie("minst_id")) },
      " ORDER BY id"
    ).then((res) => setFeeStructure(res));
  }, []);

  return (
    <div className="App">
      {subscription === true && <Outlet />}
      {subscription === false && <NotActive />}
      {beforeLoadStatus === true && <BeforeLoad />}
      <ScrollToTop />
    </div>
  );
}

export default App;
