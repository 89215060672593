import { createContext, useState } from "react";

const initial = [];

export const StatsContext = createContext(initial);

export const StatsProvider = ({ children }) => {
  const [feesTotalState, setFeesTotalState] = useState(initial);
  const [maleSdTotalState, setMaleSdTotalState] = useState(initial);
  const [femaleSdTotalState, setFemaleSdTotalState] = useState(initial);

  const setFeesTotal = (arr) => {
    setFeesTotalState(arr);
  };

  const setMaleSdTotal = (arr) => {
    setMaleSdTotalState(arr);
  };

  const setFemaleSdTotal = (arr) => {
    setFemaleSdTotalState(arr);
  };

  return (
    <StatsContext.Provider
      value={{
        feesTotalState,
        maleSdTotalState,
        femaleSdTotalState,

        setFeesTotal,
        setMaleSdTotal,
        setFemaleSdTotal,
      }}
    >
      {children}
    </StatsContext.Provider>
  );
};
