// external
import { Link } from 'react-router-dom';
import { Button, Card, Avatar} from '@mui/material/';
import { Col, Row } from 'react-bootstrap';
// internal
import * as React from 'react';
import '../../style/manage/home.css';
import { useState, useEffect } from 'react';
import { getCookie } from '../../hooks/useCookie';
import { decrypt } from '../../hooks/useSecurity';
import { numberFormate } from '../../component/number';
import ManageContHead from '../../component/manage/manageconthead';
import TotalTb, { TotalTbGraph } from '../../component/tables/totaltb';
import { CardFooter, CardTwoCol } from '../../component/cards/cards';
import { MBackend, MStorage } from '../../component/url';
import { splitDate } from '../../component/number_formate';

function MgInstFinanceHome(){

  // get page data
  const [minst_id, setMInstId] = useState("");
  const [inst_expe, setIExp] = useState("");
  const [staff_salary, setSSal] = useState("");
  const [sd, setSd] = useState("");
  const [sd_fee, setSdFee] = useState("");
  const [sd_fee_limit, setSdFeeLimit] = useState("");

  const A_SD_DRS = MBackend+"meducation/sd/sd.php?ds=1&query="; 
  const A_INST_EXP_DRS = MBackend+"mfinance/expenses.php?ds=1&query="; 
  const A_STAFF_SALARY_DRS = MBackend+"mfinance/payroll.php?ds=1&query=";
  const A_SD_FEE_DRS = MBackend+"mfinance/fees.php?ds=1&query=";
  // path 
  const SD_PROF = MStorage+"meducation/sd/prof/"; 
 
  // A_INST_EXP_DRS
  useEffect(() => {

    setMInstId(decrypt(getCookie("minst_id")));

    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onload = function() {
      var request = this.responseText;
      setIExp(JSON.parse(request));
    }
    xmlhttp.open("GET", A_INST_EXP_DRS+" Where session="+ decrypt(getCookie('session'))+" AND mf_employer="+decrypt(getCookie("mid")));
    xmlhttp.send();
    
    const xmlhttp_sd = new XMLHttpRequest();
    xmlhttp_sd.onload = function() {
      var request = this.responseText;
      setSd(JSON.parse(request));
    }
    xmlhttp_sd.open("GET", A_SD_DRS+" Where minst_id="+decrypt(getCookie("minst_id"))+ " AND mid!='0'");
    xmlhttp_sd.send();

  }, [minst_id])
  
  // A_STAFF_SALARY_DRS
  useEffect(() => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onload = function() {
      var request = this.responseText;
      setSSal(JSON.parse(request));
    }
    xmlhttp.open("GET", A_STAFF_SALARY_DRS+" Where session="+ decrypt(getCookie('session'))+" AND mf_employer="+decrypt(getCookie("mid")));
    xmlhttp.send();
  }, [minst_id])
  // A_SD_FEE_DRS
  useEffect(() => {
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onload = function() {
      var request = this.responseText;
      setSdFee(JSON.parse(request));
    }
    xmlhttp.open("GET", A_SD_FEE_DRS+" Where session="+ decrypt(getCookie('session'))+" AND mf_receiver="+decrypt(getCookie("mid")));
    xmlhttp.send();
  }, [minst_id])
  // A_SD_FEE_DRS
  useEffect(() => {
    const xmlhttp_fee = new XMLHttpRequest();
    xmlhttp_fee.onload = function() {
      var request = this.responseText;
      var rData = Object.values(JSON.parse(request));
      setSdFeeLimit(rData.reverse());
    }
    xmlhttp_fee.open("GET", A_SD_FEE_DRS+" Where session="+ decrypt(getCookie('session'))+" AND mf_receiver="+decrypt(getCookie("mid"))+" ORDER By id DESC LIMIT 5");
    xmlhttp_fee.send();
  }, [minst_id])
  // ____

  // data || graph

  // total income 
  const [fees, setFees] = useState();
  const [expenses, setExpenses] = useState();
  const [salary, setSalary] = useState();

  var total_exp = 0;
  var staff_salary_arr   = 0;
  var fee_arr   = 0;

  const totalExpenses = Object.keys(inst_expe).map(key => {
    total_exp += parseInt(inst_expe[key]['cost'])
      return total_exp
    }
  ) 

  // salary total
  const totalSalary = Object.keys(staff_salary).map(key => {
    staff_salary_arr += parseInt(staff_salary[key]['salary'])
      return staff_salary_arr
    }
  ) 

  // fee total
  const totalFee = Object.keys(sd_fee).map(key => {
    fee_arr += parseInt(sd_fee[key]['amount'])
      return fee_arr
    }
  ) 
  
  setTimeout(() => {
    setExpenses(totalExpenses[totalExpenses.length - 1]);
    setSalary(totalSalary[totalSalary.length - 1]);
    setFees(totalFee[totalFee.length - 1]);
  }, -1);


  // expenses state
  const [rent, setRent] = useState();
  const [maintenance, setMaintenance] = useState();
  const [furniture, setFurniture] = useState();
  const [marketing, setMarketing] = useState();
  const [otherExp, setOtherExp] = useState();

  // salaries state
  const [teacher, setTeacher] = useState();
  const [finance, setFinance] = useState();
  const [security, setSecurity] = useState();
  const [admin, setAdmin] = useState();
  const [otherPayroll, setOtherPayroll] = useState();

  // sd fee loop
  const sdFee = Object.keys(sd_fee_limit).map(key => 
    <div className='m-card-tb-row' id={key}>
      <div className='m-card-tb-col' >
      {Object.values(sd).filter(sd => sd.mid === sd_fee_limit[key]['mf_payer']).map(filteredSd => (
        <>
        <Row style={{alignItems:"center"}} >
          <Col sm={2} md={2} lg={2} xl={2} >
            <Avatar alt={filteredSd['fname']} src={SD_PROF+filteredSd['prof']} />
          </Col>
          <Col style={{textAlign:"left"}}>
            {filteredSd['fname']}
          </Col>
        </Row>
        </>
      ))}
      </div>
      <div className='m-card-tb-col'>
        <span>{sd_fee_limit[key]['service']}</span>
      </div>
      <div className='m-card-tb-col'>
        <span>
          {sd_fee_limit[key]['time']} &nbsp; 
          <span style={{fontSize:"12px"}}>({sd_fee_limit[key]['term']})</span>
        </span>
      </div>
      <div className='m-card-tb-col'>
        <span>
          {getCookie("currency") + " " + numberFormate(parseInt(sd_fee_limit[key]['amount']))}
        </span>
      </div>
      <div className='m-card-tb-col'>
        <span>{splitDate(sd_fee_limit[key]['date'])}</span>
      </div>
    </div>
  ) 

    // expenses loop - finding total
    var rent_arr = 0;
    var maintenance_arr = 0;
    var furniture_arr = 0;
    var marketing_arr = 0;
    var other_exp_arr = 0;
    // rent total
    const renttotal = Object.keys(inst_expe).map(key => {
        if(inst_expe[key]['account'] === "Rent") {
          rent_arr += parseInt(inst_expe[key]['cost'])
          return rent_arr
        }
      }
    ) 
    // maintenance total
    const maintenancetotal = Object.keys(inst_expe).map(key => {
        if(inst_expe[key]['account'] === "Maintenance") {
          maintenance_arr += parseInt(inst_expe[key]['cost'])
          return maintenance_arr
        }
      }
    ) 
    // furniture total
    const furnituretotal = Object.keys(inst_expe).map(key => {
        if(inst_expe[key]['account'] === "Furniture") {
          furniture_arr += parseInt(inst_expe[key]['cost'])
          return furniture_arr
        }
      }
    ) 
    // marketing total
    const marketingtotal = Object.keys(inst_expe).map(key => {
        if(inst_expe[key]['account'] === "Marketing") {
          marketing_arr += parseInt(inst_expe[key]['cost'])
          return marketing_arr
        }
      }
    ) 
    // marketing total
    const otherExptotal = Object.keys(inst_expe).map(key => {
        if(inst_expe[key]['account'] === "Other") {
          other_exp_arr += parseInt(inst_expe[key]['cost'])
          return other_exp_arr
        }
      }
    ) 
  
  // salaries loop - finding total
  var teacher_arr   = 0;
  var finance_arr   = 0;
  var security_arr  = 0;
  var admin_arr     = 0;
  var otherPayroll_arr     = 0;
  // teacher total
  const teachertotal = Object.keys(staff_salary).map(key => {
      if(staff_salary[key]['account'] === "Teacher") {
        teacher_arr += parseInt(staff_salary[key]['salary'])
        return teacher_arr
      }
    }
  ) 
  // finance total
  const financetotal = Object.keys(staff_salary).map(key => {
      if(staff_salary[key]['account'] === "Finance") {
        finance_arr += parseInt(staff_salary[key]['salary'])
        return finance_arr
      }
    }
  ) 
  // security total
  const securitytotal = Object.keys(staff_salary).map(key => {
      if(staff_salary[key]['account'] === "Security") {
        security_arr += parseInt(staff_salary[key]['salary'])
        return security_arr
      }
    }
  ) 
  // admin total
  const admintotal = Object.keys(staff_salary).map(key => {
      if(staff_salary[key]['account'] === "Admin") {
        admin_arr += parseInt(staff_salary[key]['salary'])
        return admin_arr
      }
    }
  ) 
  // other total
  const otherPayrolltotal = Object.keys(staff_salary).map(key => {
      if(staff_salary[key]['account'] === "Other") {
        otherPayroll_arr += parseInt(staff_salary[key]['salary'])
        return otherPayroll_arr
      }
    }
  ) 

  setTimeout(() => {
    // expenses
    setRent(renttotal.filter(e => e !== undefined).pop());
    setMaintenance(maintenancetotal.filter(e => e !== undefined).pop());
    setFurniture(furnituretotal.filter(e => e !== undefined).pop());
    setMarketing(marketingtotal.filter(e => e !== undefined).pop());
    setOtherExp(otherExptotal.filter(e => e !== undefined).pop());
    // salaries
    setTeacher(teachertotal.filter(e => e !== undefined).pop());
    setFinance(financetotal.filter(e => e !== undefined).pop());
    setSecurity(securitytotal.filter(e => e !== undefined).pop());
    setAdmin(admintotal.filter(e => e !== undefined).pop());
    setOtherPayroll(otherPayrolltotal.filter(e => e !== undefined).pop());
  }, 1);

  const tableRowsExpenses = [{
      title: "Rent", 
      amount: rent,
    },{
      title: "Maintenance", 
      amount: maintenance,
    },{
      title: "Marketing", 
      amount: marketing,
    },{
      title: "Furniture", 
      amount: furniture,
    },{
      title: "Other", 
      amount: otherExp,
    }
  ]
  
  const tableRowsSalary = [{
    title: "Teacher", 
    amount: teacher,
  },{
    title: "Finance", 
    amount: finance,
  },{
    title: "Security", 
    amount: security,
  },{
    title: "Administration", 
    amount: admin,
  },{
    title: "Other", 
    amount: otherPayroll,
  }
]

  return (
    <div className="mg-cont-m">
      
      {/* prof */}
      <ManageContHead 
        title={"Finance"}
      />
      {/* ______ */}

      {/* total income */}
      <Card className="m-card" variant='outlined'>
        <div className='m-card-header'>
          <h1>Income</h1>
        </div>
        <div className='m-card-cont' >  
          <Row style={{alignItems:"center"}}>
            <Col >
            <h1 className='prime-color anim-opacity' style={{textAlign:"center",fontWeight:"bolder"}}>
              <span className=''>{getCookie("currency")}</span>
              &nbsp;
              <span className='text-3xl'>{numberFormate(fees - (expenses + salary))}</span>
            </h1>
            </Col>
          </Row>
        </div>
      
        <div className='m-card-footer'>
          <Link to={'../finance/income'}>
            <Button className='secondary-btn' size='small' type="outlined">More</Button>
          </Link>
        </div>
      </Card>

      {/* inst expenses */}

      <CardTwoCol 
        head={"Total Expenses"}
        table={
          <TotalTb 
            lefthead={"Accounts"}
            righthead={"Expenses"}
            rows={tableRowsExpenses}
          />
        }
        graph={
          <TotalTbGraph 
            head={"Total Expenses"}
            rows={tableRowsExpenses}
          />
        }
        footer={
          <CardFooter 
            link={'../finance/expenses'}
          />
        }
      />

      {/* staff Payroll */}
      <CardTwoCol 
        head={"Total Payroll"}
        table={
          <TotalTb 
            lefthead={"Accounts"}
            righthead={"Amount"}
            rows={tableRowsSalary}
          />
        }
        graph={
          <TotalTbGraph 
            head={"Total Payroll"}
            rows={tableRowsSalary}
          />
        }
        footer={
          <CardFooter 
            link={'../finance/payroll'}
          />
        }
      />

      {/* sd fees */}
      <Card className="m-card" variant='outlined'>
        <div className='m-card-header'>
          <Link to={'../finance/fees'}><h1>Fees</h1></Link>  
        </div>
        <div className='m-card-cont'>  
          <Row>
            <Col className="m-tb-wide">
              <div className='m-tb-wider-cont'>

                <div className='m-card-tb-row'>
                  <div className='m-card-tb-col' style={{textAlign:"left"}}>
                    <span><b>Student</b></span>
                  </div>
                  <div className='m-card-tb-col'>
                    <span><b>Type</b></span>
                  </div>
                  <div className='m-card-tb-col'>
                    <span><b>Term</b></span>
                  </div>
                  <div className='m-card-tb-col'>
                    <span><b>Amount</b></span>
                  </div>
                  <div className='m-card-tb-col'>
                    <span><b>Date</b></span>
                  </div>
                </div>

                {sdFee}
              
              </div> 
            </Col>
          </Row>
        </div>
        <CardFooter 
          link={'../finance/fees'}
        />
      </Card>

      {/* ______ */}

    </div>
  );
};


export default MgInstFinanceHome;