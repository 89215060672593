// external
import Select from "@mui/material/Select";
import {
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Divider,
  Card,
  Snackbar,
  Avatar,
  Chip,
  Paper,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material/";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CgTrashEmpty } from "react-icons/cg";
import { Tooltip as MUITooltip } from "@mui/material/";

// internal
import * as React from "react";
import { useState } from "react";
import { getCookie } from "../../../hooks/useCookie";
import { numberFormate } from "../../../component/number";
import { Col, Row, Table } from "react-bootstrap";
import TotalTb, { TotalTbGraph } from "../../../component/tables/totaltb";
import { CardTwoCol } from "../../../component/cards/cards";
import PageProgress from "../../../component/progress";
import ManageContHead from "../../../component/manage/manageconthead";
import { ME_SD_SD, MF_FEES } from "../../../config/api";
import { FaCheckCircle, FaCircle, FaPencilAlt } from "react-icons/fa";
import { idDecrypt } from "../../../hooks/useEncryption";
import { SD_PROF } from "../../../config/mstorage";
import { FeesContext } from "../../../context/feesContext";
import { SdContext } from "../../../context/sdContext";
import { SystemContext } from "../../../context/systemContext";
import { getData } from "../../../hooks/useFetch";
import { getCustomData } from "../../../hooks/getCustomData";
import { Link } from "react-router-dom";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

export default function IFFeesForm({ setFeeId, setComponent }) {
  const { systemState } = React.useContext(SystemContext);
  const { feesState, addFees, deleteFees } = React.useContext(FeesContext);
  const { sdState } = React.useContext(SdContext);
  const [fees, setFees] = useState([]);
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject["mf_receiver"] = idDecrypt(getCookie("minst_id"));
    formDataObject["m"] = "MEducation";
    formDataObject["user"] = "MEd Student";
    formDataObject["session"] = systemState?.session;
    formDataObject["status"] = "Paid";

    fetch(MF_FEES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        const upId = data.insertId;
        formDataObject["id"] = upId;
        formDataObject["date"] = new Date();
        addFees(formDataObject);

        if (data.affectedRows === 1) {
          setTimeout(() => {
            setProgress(false);
            setResult(true);
          }, 1000);
          setTimeout(() => {
            setModelShow(false);
            setResult(false);
          }, 3000);
        } else {
          console.log("Some error occurred");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const staff_html = Object.values(sdState).map((key, index) => (
    <MenuItem id={key} value={key["mid"]}>
      <div className="flex items-center">
        <div>
          <Avatar alt={key["fname"]} src={SD_PROF + key["prof"]} />
        </div>
        <div className="ml-2">
          <b>{key["fname"]}</b> -{" "}
          <span className="text-xs">{key["program"]}</span>
        </div>
      </div>
    </MenuItem>
  ));

  const [feeType, setFeeType] = useState();
  const [term, setTerm] = useState(0);

  const changeFeeType = (event) => {
    setTimeout(() => {
      setFeeType(event.target.value);
    }, 50);
  };
  //
  const changeTerm = (event) => {
    setTimeout(() => {
      setTerm(event.target.value);
    }, 50);
  };

  const [sSd, setSSd] = useState("");

  const handleSelectStudent = (student) => {
    setSSd(student);
    setSearchValue("");
    setSearchResults([]);

    Promise.all([
      getCustomData(
        MF_FEES + "/custom",
        { mf_payer: parseInt(student?.msd_id) },
        " ORDER BY id"
      ),
    ])
      .then(([f]) => {
        setFees(f);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  };

  const [searchValue, setSearchValue] = useState("");

  const [searchResults, setSearchResults] = useState([]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);
    if (inputValue.length >= 3) {
      getData(ME_SD_SD + "/all", {
        fields: "id,msd_id,fname,lname,program,prof",
        column: "minst_id",
        value: idDecrypt(getCookie("minst_id")),
        queryFurther: ` AND fname LIKE '%${inputValue}%' OR lname LIKE '%${inputValue}%'`,
        page: 1,
        pageSize: 50,
      }).then((data) => {
        setSearchResults(data);
      });
    }
  };

  return (
    <div className="mg-cont-m">
      <PageProgress status={progress} />

      {!sSd && (
        <>
          <div className="max-w-4xl py-4 mx-auto ">
            <div className="relative">
              <input
                type="text"
                className="border !border-blue-400 bg-blue-50 focus:outline-blue-500 text-blue-500 rounded-full py-3 px-3 w-full"
                placeholder="Search for a Student"
                autoFocus
                value={searchValue}
                onChange={handleInputChange}
              />
              {searchResults.length > 0 && (
                <ul className="absolute z-10 mt-1 w-full rounded-md">
                  {searchResults.map((s) => (
                    <li
                      key={s.msd_id}
                      className="px-2 py-1 cursor-pointer border m-2 rounded-full hover:bg-gray-100"
                      onClick={() => handleSelectStudent(s)}
                    >
                      <div className="flex items-center space-x-2">
                        <div>
                          <Avatar src={SD_PROF + s.prof} alt={s.fname} />
                        </div>
                        <div className="space-x-2">
                          <span>
                            {s.fname} {s.lname}
                          </span>
                          <span>-</span>
                          <span className="text-xs">{s.program}</span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </>
      )}

      {sSd && (
        <div className="max-w-4xl mx-auto">
          <div className="px-3 py-3 border-b">
            <div className="flex items-center">
              <div className="inline-block">
                <Avatar alt={sSd.fname} src={SD_PROF + sSd?.prof} />
              </div>
              <div className="ml-2 text-left ">
                {sSd.fname} {sSd.lname}
              </div>
            </div>
          </div>

          <StudentFeesTable
            fees={fees}
            setFeeId={setFeeId}
            setComponent={setComponent}
          />

          {Object.values(fees).length <= 0 && (
            <p className="text-center py-3">No Fees</p>
          )}

          <div className="flex mt-4 space-x-2 flex-end items-center text-right justify-end">
            <div className="text-right">
              <button
                onClick={() => setSSd()}
                className={`px-2 py-1 text-sm text-center border !border-blue-500 inline-flex items-center duration-150 text-blue-500 bg-blue-100 rounded-full hover:bg-blue-200 active:bg-blue-300`}
              >
                Search Another
              </button>
            </div>
          </div>
        </div>
      )}

      <Snackbar
        open={open}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Fee Deleted Successfully.
        </Alert>
      </Snackbar>
    </div>
  );
}

const StudentFeesTable = ({ fees, setFeeId, setComponent }) => {
  const [filterService, setFilterService] = useState("");
  const [filterStatus, setFilterStatus] = useState("");

  const filteredFees = React.useMemo(() => {
    return fees.filter(
      (fee) =>
        (filterService ? fee.service.includes(filterService) : true) &&
        (filterStatus ? fee.status.includes(filterStatus) : true)
    );
  }, [fees, filterService, filterStatus]);

  const totalPaidFees = React.useMemo(() => {
    return filteredFees
      .filter((fee) => fee.status === "Paid")
      .reduce((sum, fee) => sum + parseFloat(fee.amount), 0);
  }, [filteredFees]);

  const totalUnpaidFees = React.useMemo(() => {
    return filteredFees
      .filter((fee) => fee.status !== "Paid")
      .reduce((sum, fee) => sum + parseFloat(fee.amount), 0);
  }, [filteredFees]);

  const totalFees = totalPaidFees + totalUnpaidFees;
  const paidPercentage = (totalPaidFees / totalFees) * 100;
  const unpaidPercentage = (totalUnpaidFees / totalFees) * 100;

  function handleChangeFeePay(id) {
    setComponent("Pay");
    setFeeId(id);
  }

  return (
    <div>
      {/* <Typography variant="h6" gutterBottom>
        Student Fees
      </Typography> */}
      {/* <TextField
        label="Filter by Service"
        variant="outlined"
        size="small"
        value={filterService}
        onChange={(e) => setFilterService(e.target.value)}
        style={{ margin: "10px" }}
      />
      <TextField
        label="Filter by Status"
        variant="outlined"
        size="small"
        value={filterStatus}
        onChange={(e) => setFilterStatus(e.target.value)}
        style={{ margin: "10px" }}
      /> */}
      <div className="my-2">
        <div className="flex w-full h-6">
          <div
            class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 dark:bg-green-900 dark:text-green-300"
            style={{ width: `${paidPercentage}%` }}
          >
            <span className=" text-sm !my-2 px-2">{`Paid: Rs.${numberFormate(
              totalPaidFees
            )}`}</span>
          </div>
          <div
            class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 dark:bg-red-900 dark:text-red-300"
            style={{ width: `${unpaidPercentage}%` }}
          >
            <span className="text-sm px-2">{`Unpaid: Rs.${numberFormate(
              totalUnpaidFees
            )}`}</span>
          </div>
        </div>
      </div>
      {/* <Typography variant="subtitle1" style={{ margin: "10px" }}>
        Total Paid Fees: {totalPaidFees}
      </Typography>
      <Typography variant="subtitle1" style={{ margin: "10px" }}>
        Total Unpaid Fees: {totalUnpaidFees}
      </Typography> */}
      <TableContainer component={Paper}>
        <Table size="large">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              {/* <TableCell>User</TableCell> */}
              <TableCell>Service</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Term</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredFees.map((fee) => (
              <TableRow key={fee.id}>
                <TableCell className="!py-5">{fee.id}</TableCell>
                {/* <TableCell className="!py-5">{fee.user}</TableCell> */}
                <TableCell className="!py-5">{fee.service}</TableCell>
                <TableCell className="!py-5">
                  Rs.{numberFormate(fee.amount)}
                </TableCell>
                <TableCell className="!py-5">{fee.term}</TableCell>
                <TableCell className="!py-5">{fee.time}</TableCell>
                <TableCell className="!py-5">
                  {new Date(fee.date).toLocaleDateString()}
                </TableCell>
                <TableCell className="!py-5">
                  {fee.status == "Paid" && (
                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                      Paid
                    </span>
                  )}
                  {fee.status == "Unpaid" && (
                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                      Unpaid
                    </span>
                  )}
                </TableCell>
                <TableCell className="!py-5">
                  {fee.status == "Paid" && (
                    <Link to={"../../fees/pay?id=" + fee.id}>
                      <button>Receipt</button>
                    </Link>
                  )}
                  {fee.status == "Unpaid" && (
                    <Link to={"../../fees/pay?id=" + fee.id}>
                      <button>Pay</button>
                    </Link>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
