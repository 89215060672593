import { MED_IA, MEducation } from "../config/links";

export default function NotActive() {
  return (
    <>
      <main>
        <div className="max-w-screen-xl mx-auto px-4 flex mt-5 items-top justify-start md:px-8">
          <div className="max-w-2xl mx-auto text-center">
            <div className="pb-6">
              <img
                alt="nosubscription"
                src="./logoNoBg.png"
                width={100}
                className="mx-auto"
              />
            </div>
            <h3 className="text-red-600 text-3xl font-semibold sm:text-4xl">
              Subscription isn't Active.
            </h3>
            <p className="text-gray-600 mt-4 text-sm">
              Please generate an invoice in IA and pay it through MPay.
            </p>

            <div className="flex text-center items-center justify-center mt-4">
              <div className=" text-right  p-1">
                <a href={MED_IA}>
                  <button
                    type="submit"
                    className={`px-3 py-2 text-center inline-flex items-center duration-150 bg-gray-100 rounded-full hover:bg-gray-200 active:bg-gray-300`}
                  >
                    Go to IA
                  </button>
                </a>
              </div>
              <div className=" text-right  p-1">
                <a href={MEducation}>
                  <button
                    type="submit"
                    className={`px-3 py-2 text-center inline-flex items-center duration-150 bg-gray-100 rounded-full hover:bg-gray-200 active:bg-gray-300`}
                  >
                    Go to MEd
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
