import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Avatar, IconButton } from "@mui/material";

// internal
import "../../style/manage/header.css";
import { MODULES } from "../../data/modules";
import Icons from "../icons";
import { UserContext } from "../../context/userContext";
import { INST_PROF } from "../../config/mstorage";
import { MdClose } from "react-icons/md";

function SidenavMobile({ handleSidenavMobile }) {
  const { user } = React.useContext(UserContext);

  const navBarHTML = Object.values(MODULES).map((key) => (
    <>
      <NavLink
        style={({ isActive }) => {
          return { opacity: isActive ? "0.5" : "" };
        }}
        to={key.link}
      >
        <ListItemButton className="sidenav-md-btn">
          <ListItemIcon style={{ transform: "scale(1)" }}>
            <Icons
              name={key.name}
              style={{
                transform: "scale(1.1)",
                borderRadius: "50px",
                background: "linear-gradient(90deg, #1CB5E0 0%, #1e78f4 100%)",
                color: "white",
                padding: "5px",
                fontSize: "30px",
              }}
            />
          </ListItemIcon>
          <ListItemText primary={key.name} />
        </ListItemButton>
      </NavLink>
    </>
  ));

  return (
    <div>
      <div className="flex-1 text-center">
        <div className="inline-block p-1 truncate">
          <Link to={"./"}>
            <IconButton
              sx={{ p: 0, mr: 1 }}
              style={{ transform: "scale(0.9)" }}
            >
              <Avatar
                alt={user["instData"].name}
                src={INST_PROF + user["instData"].profile}
              />
            </IconButton>
          </Link>
          <p className="text-sm">{user["instData"].name}</p>
        </div>
      </div>
      <div className="fixed top-3 right-8 bg-gray-50 shadow-lg border rounded-full">
        <IconButton
          style={{ transform: "scale(0.9)" }}
          onClick={() => handleSidenavMobile(false)}
        >
          <MdClose />
        </IconButton>
      </div>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {navBarHTML}
      </List>
    </div>
  );
}

export default SidenavMobile;
