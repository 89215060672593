import { Avatar } from "@mui/material";
import React from "react";
import { numberFormate } from "../../../../../component/number";

const ReceiptPrint = React.forwardRef(({ data }, ref) => (
  <div ref={ref} className="printable-content p-4 bg-white">
    <section>
      <div class="bg-white px-2 max-w-3xl mx-auto">
        <div class="flex items-center justify-between mb-8">
          <div class="flex items-center">
            <img
              class="w-20 mr-2"
              src="https://mstoragedb.blob.core.windows.net/med-inst-profile/1704887533241-4086.jpg"
              alt="Logo"
            />
            <div class="text-gray-700 font-semibold text-2xl">
              The MEducation School
            </div>
          </div>
          <div class="text-gray-700">
            <div class="font-bold  mb-2">
              Receipt # <span class="text-sm">{data?.id}</span>
            </div>
            <div class="text-sm">
              Date: {new Date(data?.date).toLocaleDateString()}
            </div>
          </div>
        </div>
        <div className=" mt-2 mb-4 ">
          <h2 className="text-lg mb-2">Student</h2>
          <div className="">
            <div className="flex items-center">
              <div className="inline-block">
                <Avatar
                  alt={"Amir"}
                  sizes="large"
                  src={
                    "https://mstoragedb.blob.core.windows.net/med-sd-profile/538491815.jpeg"
                  }
                />
              </div>
              <div className="ml-2 text-left ">Amir Khan</div>
            </div>
          </div>
        </div>

        <table class="w-full text-left mb-8">
          <thead>
            <tr className="border-b border-gray-200">
              <th class="text-gray-700 font-bold uppercase py-2">Sr. No</th>
              <th class="text-gray-700 font-bold uppercase py-2">Type</th>
              <th class="text-gray-700 font-bold uppercase py-2">Term</th>
              <th class="text-gray-700 font-bold uppercase py-2">Amount</th>
              <th class="text-gray-700 font-bold uppercase py-2">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td class="py-4 text-gray-700">{data?.id}</td>
              <td class="py-4 text-gray-700">{data?.service}</td>
              <td class="py-4 text-gray-700">{data?.term}</td>
              <td class="py-4 text-gray-700">
                Rs.{numberFormate(data?.amount)}
              </td>
              <td class="py-4 text-gray-700">
                <span className="text-base">
                  {data.status == "Paid" && (
                    <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                      Paid
                    </span>
                  )}
                  {data.status == "Unpaid" && (
                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                      Unpaid
                    </span>
                  )}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        {/* <div class="border-t-2 border-gray-300 pt-8 mb-8">
          <div class="text-gray-700 mb-2">
            Payment is due within 30 days. Late payments are subject to fees.
          </div>
        </div> */}
      </div>
    </section>
  </div>
));

export default ReceiptPrint;

// function AlumniAndVolunteers({ alumni, volunteers }) {
//   return (
//     <>
//       <>
//         <div className="masonry-grid masonry-grid-count-2">
//           {Object.values(alumni)?.map((a, index) => (
//             <div className="masonry-grid__item border" key={index}>
//               <div className="flex p-2" style={{ alignItems: "center" }}>
//                 <div>
//                   <Avatar
//                     alt={a.name}
//                     className="!w-10 !h-10"
//                     sizes="small"
//                     src={ALUM_PROFILE + a.profile}
//                   />
//                 </div>
//                 <div>
//                   <p className="ml-2 text-gray-500">{a.name}</p>
//                   <span className="ml-2 text-xs text">{a.yes_year}</span>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//         <div className="masonry-grid masonry-grid-count-2">
//           {volunteers?.split(",").map((a, index) => (
//             <div className="masonry-grid__item border" key={index}>
//               <div className="flex p-2" style={{ alignItems: "center" }}>
//                 <div>
//                   <p className="ml-2 text-sm text-gray-500">{a}</p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </>
//     </>
//   );
// }
