// external 
  import {Button, Stack} from '@mui/material/';
  import { Link } from 'react-router-dom';
  import Row from 'react-bootstrap/Row';
  import Col from 'react-bootstrap/Col';

// internal
  import Icons from '../icons';
import ActionBtn from './actionBtn';

/** 
 * @param {title} is 
 * */

export default function FaceCont ({text,img,btns}){


  return (
    <>
      <Row className="justify-content-center main-head" >
        <Col className='main-head-sec'>
          <h1>{text.main}</h1>
          <h3>{text.sub}</h3>
        </Col>
        <Col className='main-head-sec' style={{textAlign:"center"}}>
          <img src={img} alt="" />
        </Col>   
      </Row>
      <Row className="justify-content-center">
        
        <ActionBtn 
          data={btns}
        />

      </Row>
    </>
  )

}
