const isProduction = true; // Production
// const isProduction = false; // Development

export const M = isProduction
  ? "https://muqadar.com/"
  : "http://localhost:3000/";
export const MAccount = isProduction
  ? "https://account.muqadar.com/"
  : "http://localhost:3000/";
export const MAdmin = isProduction
  ? "https://admin.muqadar.com/"
  : "http://localhost:3000/";
export const MHostels = isProduction
  ? "https://hostels.muqadar.com/"
  : "http://localhost:3011/";
export const MBackend = isProduction
  ? "https://mbackendnodejs.azurewebsites.net/"
  : "http://localhost:5000/";
export const MStorage = isProduction
  ? "https://mstoragedb.blob.core.windows.net/"
  : "https://mstoragedb.blob.core.windows.net/";

// meducation
export const MEducation = isProduction
  ? "https://education.muqadar.com/"
  : "http://localhost:3011/";
export const MED_IA = isProduction
  ? "https://ia.education.muqadar.com/"
  : "http://localhost:3011/";
export const MED_MI = isProduction
  ? "https://mi.education.muqadar.com/"
  : "http://localhost:3011/";
