// external 
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Button, Card } from '@mui/material/';

// internal

/** 
 * @param {head} is the head for the card
 * */

export function CardTwoCol ({head,table,graph,footer,popover}){

  return (
    
    <Card className="m-card" variant='outlined'>
      <div className='m-card-header'>
        <h1>{head}</h1>  
      </div>
      <div className='m-card-cont'>  
        <Row style={{alignItems:"center"}}>
          <Col >
            
            {table}

          </Col>
          <Col style={{textAlign:"center"}}>
            <div className='m-card-graph'>
              {graph}
            </div>
          </Col>
        </Row>
      </div>
      {footer &&
        <>
          {footer}
        </>
      }
      {popover &&
        <>
          {popover}
        </>
      }
    </Card>

  )

}

// ___________

/** 
 * @param {head} is the head for the card
 * @param {body} is the main cont for the card
 * */
export function CardSingleCol ({head,body,footer,popover}){

  return (
    
    <Card className="m-card" variant='outlined'>
      <div className='m-card-header'>
        <h1>{head}</h1>  
      </div>
      <div className='m-card-cont'>  
          
        {body}

      </div>
    {footer &&
      <>
        {footer}
      </>
    }
    {popover &&
      <>
        {popover}
      </>
    }
    </Card>

  )

}
// ___________

/**  
 * @param {head} is the head for the card
 * @param {table} is the main cont for the card
 * */
export function CardFooter ({link}){

  return (
    <>
    <div className='m-card-footer'>
      <Link to={link}>
        <Button className='secondary-btn' size='small' type="outlined">More</Button>
      </Link>
    </div>
    </>
  )

}
// ___________

/** 
 * @param {head} is the head for the card
 * @param {table} is the main cont for the card
 * */
export function CardMasonry (param){

  return (
    <>
      <Card className="masonry-grid__item" variant='outlined'>
        <div className='m-card-header'>
          <h1>{param.head}</h1>  
        </div>
        <div className='m-card-cont ' style={{textAlign:"center"}}>  
          {param.children}
        </div>
      </Card>
    </>
  )

}
// ___________