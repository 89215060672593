import { createContext, useState } from "react";

const initialStaff = [];

export const StaffContext = createContext(initialStaff);

export const StaffProvider = ({ children }) => {
  const [staffState, setStaffState] = useState(initialStaff);
  
  const setStaff = (newStaff) => {
    setStaffState(newStaff);
  };

  return (
    <StaffContext.Provider
      value={{
        staffState,

        setStaff,
      }}
    >
      {children}
    </StaffContext.Provider>
  );
};
