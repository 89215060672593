import * as React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Card } from "@mui/material";

// internal
import "../style/manage/header.css";
import Icons from "../component/icons";
import { MODULES } from "../data/modules";

// function MInstSidenav({ status }) {
//   const navBarHTML = MODULES.map((key) => (
//     <>
//       <NavLink
//         style={({ isActive }) => {
//           return { opacity: isActive ? "0.5" : "" };
//         }}
//         to={key.link}
//       >
//         <ListItemButton className="sidenav-md-btn">
//           <ListItemIcon
//             style={
//               status === true
//                 ? { position: "relative", left: "20px" }
//                 : { transform: "scale(1)" }
//             }
//           >
//             <Icons
//               name={key.name}
//               style={{
//                 transform: "scale(1.1)",
//                 borderRadius: "50px",
//                 background: "linear-gradient(90deg, #1CB5E0 0%, #1e78f4 100%)",
//                 color: "white",
//                 padding: "5px",
//                 fontSize: "30px",
//               }}
//             />
//           </ListItemIcon>
//           {status === false && <ListItemText primary={key.name} />}
//         </ListItemButton>
//       </NavLink>
//     </>
//   ));

//   return (
//     <Card
//       className="sidenav-cont"
//       style={{ border: "none", boxShadow: "none" }}
//     >
//       <List
//         sx={{ width: "100%", bgcolor: "background.paper" }}
//         component="nav"
//         aria-labelledby="nested-list-subheader"
//         subheader={
//           <ListSubheader component="div" id="nested-list-subheader">
//             MFinance
//           </ListSubheader>
//         }
//       >
//         {navBarHTML}
//       </List>
//     </Card>
//   );
// }

function MInstSidenav({ status }) {
  const location = useLocation();
  const pathSegments = location.pathname.split("/");
  const currentSegment = pathSegments[1] || ""; // Get the part immediately after the main URL

  const navBarHTML = MODULES.map((key) => {
    const linkSegment = key.link.split("/")[0]; // Get the first part of the link value
    const isActive = currentSegment === linkSegment;

    return (
      <NavLink
        key={key.name}
        style={{ opacity: isActive ? "0.5" : "" }}
        to={key.link}
      >
        <ListItemButton className="sidenav-md-btn">
          <ListItemIcon
            style={
              status === true
                ? { position: "relative", left: "20px" }
                : { transform: "scale(1)" }
            }
          >
            <Icons
              name={key.name}
              style={{
                transform: "scale(1.1)",
                borderRadius: "50px",
                background: "linear-gradient(90deg, #1CB5E0 0%, #1e78f4 100%)",
                color: "white",
                padding: "5px",
                fontSize: "30px",
              }}
            />
          </ListItemIcon>
          {status === false && <ListItemText primary={key.name} />}
        </ListItemButton>
      </NavLink>
    );
  });

  return (
    <Card
      className="sidenav-cont"
      style={{ border: "none", boxShadow: "none" }}
    >
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            MFinance
          </ListSubheader>
        }
      >
        {navBarHTML}
      </List>
    </Card>
  );
}

export default MInstSidenav;
