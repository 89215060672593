import { createContext, useEffect, useState } from "react";
import { SystemProvider } from "./systemContext";
import { ExpensesProvider } from "./expensesContext";
import { FeesProvider } from "./feesContext";
import { PayrollProvider } from "./payrollContext";
import { StaffProvider } from "./staffContext";
import { SdProvider } from "./sdContext";
import { FeeStructureProvider } from "./feeStructureContext";
import { StatsProvider } from "./statsContext";

const muser = {
  mid: 0,
  instData: 0,
  msubscriptions: [],
  maccount: [],
};

export const UserContext = createContext(muser);

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(muser);
  const [sub, setSub] = useState(false);

  useEffect(() => {
    const subCheck = Object.values(user["msubscriptions"]).filter(
      (s) => s.mp == "MFinance"
    );
    if (subCheck.length >= 1) {
      setSub(true);
    }
  }, [user]);

  const startSession = (midpar, instData, msubscriptions, maccount) => {
    setUser((user) => ({
      mid: midpar,
      instData: instData,
      msubscriptions: msubscriptions,
      maccount: maccount,
    }));
  };

  return (
    <UserContext.Provider value={{ user, sub, startSession }}>
      <SystemProvider>
        <ExpensesProvider>
          <FeesProvider>
            <PayrollProvider>
              <StaffProvider>
                <SdProvider>
                  <FeeStructureProvider>
                    <StatsProvider>{children}</StatsProvider>
                  </FeeStructureProvider>
                </SdProvider>
              </StaffProvider>
            </PayrollProvider>
          </FeesProvider>
        </ExpensesProvider>
      </SystemProvider>
    </UserContext.Provider>
  );
};
