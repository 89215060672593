// external
import { IconButton, Tooltip } from "@mui/material/";
import { GrFormAdd } from "react-icons/gr";

// internal
import "./manageconthead.css";
import Icons from "../icons";

// _______ //

/**
 * @param {title} is the text for head
 * @param {func} is the function that will open the modal
 * @return {jsx} for the head
 * */

function ManageContHead({ title, func }) {
  return (
    <div className="mg-head">
      <div className="mg-head-l" style={{ textAlign: "left" }}>
        <h4 className="secondary-color flex manage_cont_head text-2xl">
          <Icons
            name={title}
            style={{
              transform: "scale(1.1)",
              borderRadius: "50px",
              background: "linear-gradient(90deg, #1CB5E0 0%, #1e78f4 100%)",
              color: "white",
              padding: "5px",
              fontSize: "30px",
            }}
          />
          <p className="flex ml-3 text-blue-500">{title}</p>
        </h4>
      </div>
      {func && (
        <div className="mg-head-r">
          <Tooltip title="Add - space">
            <IconButton size="medium" onClick={func}>
              <GrFormAdd />
            </IconButton>
          </Tooltip>
        </div>
      )}
    </div>
  );
}

export default ManageContHead;
