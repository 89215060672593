// external
import Select from "@mui/material/Select";
import {
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Divider,
  Card,
  Snackbar,
} from "@mui/material/";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CgTrashEmpty } from "react-icons/cg";
import { Tooltip as MUITooltip } from "@mui/material/";

// internal
import * as React from "react";
import "../../style/manage/home.css";
import { useState } from "react";
import { getCookie } from "../../hooks/useCookie";
import { numberFormate } from "../../component/number";
import { Col, Row } from "react-bootstrap";
import TotalTb, { TotalTbGraph } from "../../component/tables/totaltb";
import { CardTwoCol } from "../../component/cards/cards";
import PageProgress from "../../component/progress";
import ManageContHead from "../../component/manage/manageconthead";
import { MF_EXPENSES } from "../../config/api";
import { ExpensesContext } from "../../context/expensesContext";
import { FaCheckCircle, FaCircle, FaPencilAlt } from "react-icons/fa";
import { idDecrypt } from "../../hooks/useEncryption";
import { getActualDate } from "../../utils/dateFormate";
import { SystemContext } from "../../context/systemContext";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

export default function MFExpenses() {
  const { systemState } = React.useContext(SystemContext);
  const { expensesState, addExpenses, deleteExpenses } =
    React.useContext(ExpensesContext);
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject["mf_employer"] = idDecrypt(getCookie("minst_id"));
    formDataObject["session"] = systemState?.session;

    fetch(MF_EXPENSES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        const upId = data.insertId;
        formDataObject["id"] = upId;
        formDataObject["date"] = new Date();
        addExpenses(formDataObject);

        if (data.affectedRows === 1) {
          setTimeout(() => {
            setProgress(false);
            setResult(true);
          }, 1000);
          setTimeout(() => {
            setModelShow(false);
            setResult(false);
          }, 3000);
        } else {
          console.log("Some error occurred");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const delPayroll = (id) => {
    let person = prompt("Please type 'Delete' to Confirm ", "");
    if (person === "Delete") {
      setProgress(true);

      setTimeout(() => {
        setProgress(false);
        deleteExpenses(id);
      }, 1000);

      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onload = function () {
        setTimeout(() => {
          setOpen(true);
        }, 1000);
      };

      xmlhttp.open("DELETE", MF_EXPENSES);
      xmlhttp.setRequestHeader("Content-Type", "application/json");

      const requestBody = JSON.stringify({ id: id });
      xmlhttp.send(requestBody);

      setTimeout(() => {
        setOpen(false);
      }, 2500);
    }
  };
  // ____

  const handleShow = () => setModelShow(true);

  // expenses month loop
  const updateCards = Object.values(expensesState).map((key, index) => (
    <div className="m-card-tb-row anim-opacity" key={index}>
      <div className="m-card-tb-col" style={{ textAlign: "left" }}>
        <span>
          <b className="secondary-color">{key["account"]}</b>
        </span>
      </div>
      <div className="m-card-tb-col truncate" style={{ textAlign: "left" }}>
        <span>{key["subject"]}</span>
      </div>
      <div className="m-card-tb-col truncate" style={{ textAlign: "left" }}>
        <MUITooltip title={key["description"]} arrow>
          <span className="text-sm">{key["description"]}</span>
        </MUITooltip>
      </div>
      <div className="m-card-tb-col">
        <span>{"Rs." + numberFormate(key["cost"])}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{getActualDate(key["date"])}</span>
      </div>
      <div className="m-card-tb-col" style={{ textAlign: "right" }}>
        <MUITooltip title="Delete" arrow>
          <IconButton
            size="small"
            onClick={() => delPayroll(parseInt(key["id"]))}
          >
            <CgTrashEmpty />
          </IconButton>
        </MUITooltip>
      </div>
    </div>
  ));

  function calculateTotalCostByAccount(array, account) {
    let totalCost = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].account === account) {
        totalCost += parseInt(array[i].cost);
      }
    }
    return totalCost;
  }

  const tableRows = [
    {
      title: "Rent",
      amount: calculateTotalCostByAccount(expensesState, "Rent"),
    },
    {
      title: "Maintenance",
      amount: calculateTotalCostByAccount(expensesState, "Maintenance"),
    },
    {
      title: "Marketing",
      amount: calculateTotalCostByAccount(expensesState, "Marketing"),
    },
    {
      title: "Furniture",
      amount: calculateTotalCostByAccount(expensesState, "Furniture"),
    },
    {
      title: "Other",
      amount: calculateTotalCostByAccount(expensesState, "Other"),
    },
  ];

  return (
    <div className="mg-cont-m">
      <PageProgress status={progress} />
      <ManageContHead title={"Expenses"} func={handleShow} />

      <CardTwoCol
        head={"Total Expenses"}
        table={
          <TotalTb
            lefthead={"Accounts"}
            righthead={"Expenses"}
            rows={tableRows}
          />
        }
        graph={<TotalTbGraph head={"Total Expenses"} rows={tableRows} />}
      />

      <Card className="m-card" variant="outlined">
        <div className="m-card-header">
          <Row>
            <Col>
              <h1>Entries</h1>{" "}
            </Col>
            <Col style={{ textAlign: "right" }}>
              {/* <Chip label={"Total Entries - "+ total} /> */}
            </Col>
          </Row>
        </div>
        <div className="m-card-cont">
          <Row>
            <Col className="m-tb-wide">
              <div className="m-tb-wider-cont">
                <div className="m-card-tb-row">
                  <div className="m-card-tb-col" style={{ textAlign: "left" }}>
                    <span>
                      <b>Accounts</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col" style={{ textAlign: "left" }}>
                    <span>
                      <b>Subject</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col" style={{ textAlign: "left" }}>
                    <span>
                      <b>Description</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col">
                    <span>
                      <b>Amount</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col">
                    <span>
                      <b>Date</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col" style={{ textAlign: "right" }}>
                    <span>
                      <b>Actions</b>
                    </span>
                  </div>
                </div>

                {updateCards}
              </div>
              {/* {limit <= total &&
                <div className='m-card-tb-row' style={{padding:"0",marginTop:"5px"}}>
                  <div className='m-card-tb-col' style={{padding:"0"}}>
                    <button 
                    style={{
                      width:"100%",
                      padding:"14px",
                      margin:"0px",
                      border:"0",
                      backgroundColor: "#dcdcdc30"
                    }}
                    onClick={()=>showMore()}><b style={{fontSize:"20px"}}>+{parseInt(total) - parseInt(limit)}</b>
                    
                    </button>
                  </div>
                </div>
              } */}
            </Col>
          </Row>
        </div>
      </Card>

      {modelShow ? (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full"
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "saturate(180%) blur(5px)",
            }}
            onClick={() => setModelShow(false)}
          ></div>
          <div className="flex items-baseline min-h-screen px-2 py-2 md:!px-4 md:!py-8">
            <div className="relative w-full max-w-xl mx-auto bg-white rounded-lg shadow-md border border-gray-50">
              <div className="flex items-center justify-between px-4 py-3 border-b">
                <h4 className="text-xl font-medium text-gray-800">
                  <div className="flex" style={{ alignItems: "center" }}>
                    Add Expense
                  </div>
                </h4>
                <button
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => setModelShow(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <form
                action={MF_EXPENSES}
                method="POST"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="space-y-2 p-4 text-[15.5px] leading-relaxed text-gray-500">
                  {/* Category */}
                  <FormControl
                    className="mg-modal-in"
                    variant="outlined"
                    sx={{ textAlign: "left" }}
                    fullWidth
                    required
                  >
                    <InputLabel id="account">Account</InputLabel>
                    <Select
                      labelId="account"
                      id="account"
                      defaultValue={""}
                      label="Account"
                      name="account"
                    >
                      <MenuItem value="Rent">Rent</MenuItem>
                      <MenuItem value="Maintenance">Maintenance</MenuItem>
                      <MenuItem value="Marketing">Marketing</MenuItem>
                      <MenuItem value="Furniture">Furniture</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </Select>
                  </FormControl>

                  <Divider style={{ margin: "5px 0px" }} />

                  {/* Subject */}
                  <TextField
                    required
                    id="subject"
                    name="subject"
                    multiline
                    label="Title"
                    rows={1}
                    fullWidth
                    variant="outlined"
                  />

                  <Divider style={{ margin: "5px 0px" }} />

                  {/* Cost */}
                  <TextField
                    required
                    id="cost"
                    name="cost"
                    multiline
                    label="Cost"
                    rows={1}
                    fullWidth
                    variant="outlined"
                  />

                  <Divider style={{ margin: "5px 0px" }} />

                  {/* description */}
                  <TextField
                    id="description"
                    name="description"
                    multiline
                    rows={6}
                    fullWidth
                    label="Description"
                    variant="outlined"
                  />

                  <Divider style={{ margin: "5px 0px" }} />

                  {result === "True" && (
                    <Alert severity="success">
                      Expenses Added Successfully.
                    </Alert>
                  )}
                </div>

                {result === true && (
                  <>
                    <Alert severity="success">
                      Expense Added Successfully.
                    </Alert>
                  </>
                )}

                <div className="text-right items-center px-4 py-3 border-t">
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disableElevation={true}
                    className="prime-btn !rounded-md"
                  >
                    {progress === true && result === false ? (
                      <FaCircle className="mr-2" /> // Display a circle icon for "Editing in progress"
                    ) : result === true ? (
                      <FaCheckCircle className="mr-2" /> // Display a checkmark icon for "Message Edited"
                    ) : (
                      <FaPencilAlt className="mr-2" /> // Display a pencil icon for "Edit Message" (you can replace this with an appropriate edit icon)
                    )}
                    {progress === true && result === false
                      ? "Adding"
                      : result === true
                      ? "Expense Added"
                      : "Add Expense"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Snackbar
        open={open}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Expense Deleted Successfully.
        </Alert>
      </Snackbar>
    </div>
  );
}
