// number formate function
const numberFormate = (number) => {
  return new Intl.NumberFormat().format(number)
}

export default numberFormate;

export function splitDate(params) {
  var year = params.toString().slice(0,4);
  var month = params.toString().slice(4,6);
  var date = params.toString().slice(6,8);
  let fullDate = date + "-" + month + "-" + year;
  return fullDate;
}