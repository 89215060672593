// external

// internal
import * as React from "react";
import { useState } from "react";
import ManageContHead from "../../component/manage/manageconthead";
import Nav from "./components/nav";
import { Outlet } from "react-router-dom";

export default function MFFees() {
  const [component, setComponent] = useState("Total");

  return (
    <div className="mg-cont-m">
      <ManageContHead title={"Fees"} />

      <Nav component={component} setComponent={setComponent} />

      <Outlet />
    </div>
  );
}
