import { MdHome } from "react-icons/md";
import React from "react";
import { Link, useLocation } from "react-router-dom";

// export default function Nav({ component, setComponent, children }) {
//   const navBtns = [
//     { name: "Total", icon: <MdHome />, link: "../fees/total" },
//     { name: "Fees", icon: <MdHome />, link: "../fees/fees" },
//     { name: "Student", icon: <MdHome />, link: "../fees/student" },
//     { name: "Pay", icon: <MdHome />, link: "../fees/pay" },
//   ];

//   return (
//     <div className="bottom-0 left-0 w-full">
//       <div className="max-w-6xl !text-center md:!text-left bg-gray-50 rounded-full px-3 opacity-80 mx-auto !py-2 md:!py-2">
//         <div className="flex items-center">
//           <div>
//             {navBtns.map((b, index) => (
//               <button
//                 key={index}
//                 onClick={() => setComponent(b.name)}
//                 className={`border text-sm md:text-base rounded-full px-3 py-2 mx-2 transition-colors duration-300 ease-in-out ${
//                   b.name === component
//                     ? "bg-blue-100 text-blue-600 !border-blue-500"
//                     : "hover:bg-gray-200"
//                 }`}
//               >
//                 <Link to={b.link}>{b.name}</Link>
//               </button>
//             ))}
//           </div>
//           <div className="flex-1">{children}</div>
//         </div>
//       </div>
//     </div>
//   );
// }

export default function Nav({ component, setComponent, children }) {
  const location = useLocation();
  const currentPath = location.pathname.split("/").pop();

  const navBtns = [
    { name: "Total", icon: <MdHome />, link: "../fees/total" },
    { name: "Fees", icon: <MdHome />, link: "../fees/fees" },
    { name: "Student", icon: <MdHome />, link: "../fees/student" },
    { name: "Pay", icon: <MdHome />, link: "../fees/pay" },
  ];

  return (
    <div className="bottom-0 left-0 w-full">
      <div className="max-w-6xl !text-center md:!text-left bg-gray-50 rounded-full px-3 opacity-80 mx-auto !py-2 md:!py-2">
        <div className="flex items-center">
          <div>
            {navBtns.map((b, index) => {
              const linkPath = b.link.split("/").pop();
              const isActive = currentPath === linkPath;

              return (
                <button
                  key={index}
                  onClick={() => setComponent(b.name)}
                  className={`border text-sm md:text-base rounded-full px-3 py-2 mx-2 transition-colors duration-300 ease-in-out ${
                    isActive
                      ? "bg-blue-100 text-blue-600 !border-blue-500"
                      : "hover:bg-gray-200"
                  }`}
                >
                  <Link to={b.link}>{b.name}</Link>
                </button>
              );
            })}
          </div>
          <div className="flex-1">{children}</div>
        </div>
      </div>
    </div>
  );
}
