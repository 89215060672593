import { createContext, useEffect, useState } from "react";

const initial = [];

export const SystemContext = createContext(initial);

export const SystemProvider = ({ children }) => {
  const [systemState, setSystemState] = useState(initial);
  const [systemId, setSystemId] = useState("");

  useEffect(() => {
    setSystemId(systemState['minst_id']);
  },[systemState])

  const setSystem = (newSystem) => {
    setSystemState(newSystem);
  };

  return (
    <SystemContext.Provider
      value={{
        systemState,
        systemId,

        setSystem
      }}
    >
      {children}
    </SystemContext.Provider>
  );
};
