import { createContext, useState } from "react";

const initialPayroll = [];

export const PayrollContext = createContext(initialPayroll);

export const PayrollProvider = ({ children }) => {
  const [payrollState, setPayrollState] = useState(initialPayroll);
  
  const setPayroll = (newPayroll) => {
    setPayrollState(newPayroll);
  };

  const addPayroll = (newPayroll) => {
    setPayrollState((prevPayroll) => [newPayroll, ...prevPayroll]);
  };
  
  const updatePayroll = (PayrollId, updatedPayroll) => {
    setPayrollState((prevPayroll) =>
      prevPayroll.map((Payroll) =>
        Payroll.id === PayrollId ? { ...Payroll, ...updatedPayroll } : Payroll
      )
    );
  };

  const deletePayroll = (PayrollId) => {
    setPayrollState((prevPayroll) =>
      prevPayroll.filter((Payroll) => Payroll.id !== PayrollId)
    );
  };

  return (
    <PayrollContext.Provider
      value={{
        payrollState,

        setPayroll,
        addPayroll,
        updatePayroll,
        deletePayroll,
      }}
    >
      {children}
    </PayrollContext.Provider>
  );
};
