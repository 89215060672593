// external 
  // mui components
  import {LinearProgress} from '@mui/material/';
  // icons

// internal

/* 
 * @param {title} is the head for the popover
 * @param {text} is the body for the popover
 * */

export default function PageProgress ({status}){

  return (
    <>
      <LinearProgress className='progress-line'
        color='inherit'
        style={{
          position:"fixed",
          top:"0",
          left:"0",
          color:"#1e78f4",
          zIndex:"10000000",
          width:"100%",
          display:status === true ? "block" : "none",
        }}
      />
    </>
  )

}

export function FormProgress ({status}){

  return (
    <>
      <LinearProgress className='progress-line'
        color='inherit'
        style={{
          position:"absolute",
          top:"0",
          left:"0",
          color:"#1e78f4",
          zIndex:"10000000",
          width:"100%",
          display:status === true ? "block" : "none",
        }}
      />
    </>
  )

}

export function FormProgressBottom ({status}){

  return (
    <>
      <LinearProgress className='progress-line'
        color='inherit'
        style={{
          position:"absolute",
          bottom:"0",
          left:"0",
          color:"#1e78f4",
          zIndex:"10000000",
          width:"100%",
          display:status === true ? "block" : "none",
        }}
      />
    </>
  )

}

export function DeleteProgress ({status}){

  return (
    <>
      <LinearProgress className='progress-line'
        color='inherit'
        style={{
          position:"fixed",
          bottom:"0",
          left:"0",
          color:"#1e78f4",
          zIndex:"10000000",
          width:"100%",
          display:status === true ? "block" : "none",
        }}
      />
    </>
  )

}