// external 
  // mui components
  // icons

import { useEffect } from "react";

// internal

/** 
 * @param {title} is the head for the popover
 * */

function useKeyModel(setShow,first,second){

  useEffect(() => {
    document.addEventListener('keydown', detectKeyDown, true);
  }, [])

  const detectKeyDown = (e) => {
    if(e.key === first) {
      setShow(true);
    }else if(e.key === second) {
      setShow(false);
    }
  }

}

export default useKeyModel;