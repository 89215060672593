// external 
  // mui components
  import {Button, Stack} from '@mui/material/';
  import { Link } from 'react-router-dom';
  import Icons from '../icons';
  // icons

// internal

/** 
 * @param {title} is the head for the popover
 * @param {text} is the body for the popover
 * */

export default function actionBtn ({data}){

  const action_btns = Object.keys(data).map(key => 
    <Link to={data[key]['link']} >
      <Button 
        type='primary' 
        className='main-btn prime-btn' 
        startIcon={<Icons name={data[key]['title']}/>} 
        size='large' 
        variant="contained" 
        style={{textTransform:"none"}} 
      >
        {data[key]['title']}
      </Button>
    </Link>
  ) 

  return (
    <>
      <div className='main-btns'>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 2 }} justifyContent="center" alignItems="center">
          {action_btns}
        </Stack>
      </div>
    </>
  )

}
