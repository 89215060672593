// external
// chart.js
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut } from "react-chartjs-2";

// internal
import { numberFormate } from "../../component/number";
import { getCookie } from "../../hooks/useCookie";

// chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

/*
 * @param {righthead} is the right head for the table
 * @param {lefthead} is the left head for the table
 * @param {rows} is the total row output
 * */

export default function TotalTb({ righthead, lefthead, rows }) {
  const outputRows = rows.map((row) => (
    <div className="m-card-tb-row">
      <div className="m-card-tb-col">
        <span>{row.title}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{"Rs." + numberFormate(parseInt(row.amount))}</span>
      </div>
    </div>
  ));

  rows.sum = function (items, prop) {
    return items.reduce(function (a, b) {
      return a + ~~b[prop];
    }, 0);
  };

  const total = rows.sum(rows, "amount");

  return (
    <div className="m-card-tb">
      <div className="m-card-tb-row">
        <div className="m-card-tb-col" style={{ textAlign: "left" }}>
          <span>
            <b>{lefthead}</b>
          </span>
        </div>
        <div className="m-card-tb-col">
          <span>
            <b>{righthead}</b>
          </span>
        </div>
      </div>

      {outputRows}

      <div className="m-card-tb-row">
        <div className="m-card-tb-col">
          <span>
            <b>Total</b>
          </span>
        </div>
        <div className="m-card-tb-col">
          <span>
            <b>{"Rs." + numberFormate(total)}</b>
          </span>
        </div>
      </div>
    </div>
  );
}

export function TotalTbGraph({ head, rows }) {
  let amountArr = rows.map((row) => row.amount);
  let labelsArr = rows.map((row) => row.title);

  // graph
  const staff_salary_graph = {
    labels: labelsArr,
    datasets: [
      {
        label: head,
        data: amountArr,
        backgroundColor: [
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 99, 132, 0.2)",
          "rgba(155, 199, 32, 0.2)",
          "rgba(100, 130, 132, 0.2)",
          "rgba(10, 13, 13, 0.2)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(255, 99, 132, 1)",
          "rgba(155, 199, 32, 1)",
          "rgba(100, 130, 132, 1)",
          "rgba(10, 13, 13, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <>
      <Doughnut data={staff_salary_graph} />
    </>
  );
}
