import { createContext, useState } from "react";

const initialFees = [];

export const FeesContext = createContext(initialFees);

export const FeesProvider = ({ children }) => {
  const [feesState, setFeesState] = useState(initialFees);

  const setFees = (newFees) => {
    setFeesState(newFees);
  };

  const addFees = (newFees) => {
    setFeesState((prevFees) => [newFees, ...prevFees]);
  };

  const addManyFees = (manyFees) => {
    setFeesState((prevFees) => [...manyFees.flat(), ...prevFees]);
  };

  const updateFees = (FeesId, updatedFees) => {
    setFeesState((prevFees) =>
      prevFees.map((Fees) =>
        Fees.id === FeesId ? { ...Fees, ...updatedFees } : Fees
      )
    );
  };

  const deleteFees = (FeesId) => {
    setFeesState((prevFees) => prevFees.filter((Fees) => Fees.id !== FeesId));
  };

  return (
    <FeesContext.Provider
      value={{
        feesState,

        setFees,
        addFees,
        addManyFees,
        updateFees,
        deleteFees,
      }}
    >
      {children}
    </FeesContext.Provider>
  );
};
