// external
import { Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Col, Row } from 'react-bootstrap';
import { Card} from '@mui/material';

// internal
import * as React from 'react';
import '../../style/manage/home.css';
import { useState } from 'react';
import { numberFormate } from '../../component/number';
import ManageContHead from '../../component/manage/manageconthead';
import { ExpensesContext } from '../../context/expensesContext';
import { PayrollContext } from '../../context/payrollContext';
import { FeesContext } from '../../context/feesContext';

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

export default function MFIncome(){

  const {expensesState} = React.useContext(ExpensesContext)
  const {payrollState} = React.useContext(PayrollContext)
  const {feesState} = React.useContext(FeesContext)

  // ____ 

  // expenses data || graph

  // expenses state
  const [expenses, setExpenses] = useState();
  const [salary, setSalary] = useState();
  const [fees, setFees] = useState();
  
  // graph
  const expenses_month = {
    labels: ['Net Income', 'Total Expenses'],
    datasets: [
      {
        label: 'Institute Expenses',
        data: [fees - (expenses + salary), expenses+salary],
        backgroundColor: [
          'rgba(54, 162, 235, 0.2)',
          '#ffe0e699',
        ],
        borderColor: [
          'rgba(54, 162, 235, 1)',
          '#ffe0e699',
        ],
        borderWidth: 1,
      },
    ],
  };

  // expenses loop - finding total
  var total_exp = 0;
  var staff_salary_arr   = 0;
  var fee_arr   = 0;

  const totalExpenses = Object.keys(expensesState).map(key => {
        total_exp += parseInt(expensesState[key]['cost'])
        return total_exp
    }
  ) 
 
  // salary total
  const totalSalary = Object.keys(payrollState).map(key => {
    staff_salary_arr += parseInt(payrollState[key]['salary'])
      return staff_salary_arr
    }
  ) 

  // fee total
  const totalFee = Object.keys(feesState).map(key => {
    fee_arr += parseInt(feesState[key]['amount'])
      return fee_arr
    }
  ) 

  setTimeout(() => {
    
    setExpenses(totalExpenses[totalExpenses.length - 1]);
    setSalary(totalSalary[totalSalary.length - 1]);
    setFees(totalFee[totalFee.length - 1]);
  
  }, -1);

  return (
    <div className="mg-cont-m">
      
      <ManageContHead 
        title={"Income"}
      />

      <Card className="m-card" variant='outlined'>
        <div className='m-card-header'>
          <h1>Net Income</h1> 
        </div>
        <div className='m-card-cont'>  
          <Row style={{alignItems:"center"}}>
            <Col sm>

              <div className='m-card-tb'>

                <div className='m-card-tb-row'>
                  <div className='m-card-tb-col' style={{textAlign:"left"}}>
                    <span>Fees</span>
                  </div>
                  <div className='m-card-tb-col' style={{textAlign:"right"}}>
                    <span style={{color:"#00c853"}}>	&uarr; {"Rs." +  numberFormate(fees)}</span>
                  </div>
                </div>

                <div className='m-card-tb-row'>
                  <div className='m-card-tb-col' style={{textAlign:"left"}}>
                    <span>Expenses</span>
                  </div>
                  <div className='m-card-tb-col' style={{textAlign:"right"}}>
                    <span style={{color:"#c62828"}}> &darr; {"Rs." +  numberFormate(expenses)}</span>
                  </div>
                </div>

                <div className='m-card-tb-row'>
                  <div className='m-card-tb-col' style={{textAlign:"left"}}>
                    <span>Payroll</span>
                  </div>
                  <div className='m-card-tb-col' style={{textAlign:"right"}}>
                    <span style={{color:"#c62828"}}>&darr; {"Rs." + numberFormate(salary)}</span>
                  </div>
                </div>

                <div className='m-card-tb-row'>
                  <div className='m-card-tb-col' style={{textAlign:"right"}}>
                    <span>Net Income</span>
                  </div>
                  <div className='m-card-tb-col' style={{textAlign:"right"}}>
                    <span>{"Rs." + numberFormate(fees - (expenses + salary))}</span>
                  </div>
                </div>

              </div> 

            </Col>
            <Col sm>

              <div className="m-card" style={{border:"none"}}>
                <div className='m-card-cont' style={{padding:"20px"}}>  
                  <h1 className='prime-color anim-opacity' style={{textAlign:"center",fontWeight:"bolder"}}>
                    <span className='text-3xl'>{"Rs."+ numberFormate(fees - (expenses + salary))}</span>
                  </h1>
                </div>
              </div>

            </Col>
            <Col style={{textAlign:"center"}} sm>
              <div className='m-card-graph'>
                <Doughnut data={expenses_month} />
              </div>
            </Col>
          </Row>
        </div>
      </Card>

    </div>
  );
};

