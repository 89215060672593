import { MBackend } from "./links";

export const M_MA = MBackend + "m/maccount";
export const M_MR = MBackend + "m/mrequests";
export const MDOCS = MBackend + "mdocuments/documents";
export const MSUBSCRIPTIONS = MBackend + "m/msubscriptions";

export const ME_PRODUCTS = MBackend + "meducation/med/products";
export const ME_ROLES = MBackend + "meducation/med/roles";

export const ME_INST_CLS = MBackend + "meducation/inst/classes";
export const ME_INST_CLS_CR = MBackend + "meducation/inst/classescourses";
export const ME_INST_CR = MBackend + "meducation/inst/courses";
export const ME_INST_DEP = MBackend + "meducation/inst/departments";
export const ME_INST_FAC = MBackend + "meducation/inst/facilities";
export const ME_INST_FEES = MBackend + "meducation/inst/fees";
export const ME_INST_GAL = MBackend + "meducation/inst/gallary";
export const ME_INST_INST = MBackend + "meducation/inst/institute";
export const ME_INST_IP = MBackend + "meducation/inst/instproducts";
export const ME_INST_MSG = MBackend + "meducation/inst/messages";
export const ME_INST_MPI = MBackend + "meducation/inst/mpi";
export const ME_INST_PROG = MBackend + "meducation/inst/programs";
export const ME_INST_ROLES = MBackend + "meducation/inst/roles";
export const ME_INST_SESS = MBackend + "meducation/inst/sessions";
export const ME_INST_UPD = MBackend + "meducation/inst/updates";

export const ME_PC_PROGRAMS = MBackend + "meducation/pc/programs";
export const ME_PC_COURSES = MBackend + "meducation/pc/courses";

export const ME_PARENT = MBackend + "meducation/parent/parent";

export const ME_SD_ACA = MBackend + "meducation/sd/sdacademics";
export const ME_SD_APP = MBackend + "meducation/sd/sdappli";
export const ME_SD_ATT = MBackend + "meducation/sd/sdattendance";
export const ME_SD_INST = MBackend + "meducation/sd/sdinst";
export const ME_SD_SD = MBackend + "meducation/sd/sd";

export const ME_STAFF_ATT = MBackend + "meducation/staff/staffattendance";
export const ME_STAFF_INST = MBackend + "meducation/staff/staffinst";
export const ME_STAFF = MBackend + "meducation/staff/staff";

export const MF_EXPENSES = MBackend + "mfinance/expenses";
export const MF_FEES = MBackend + "mfinance/fees";
export const MF_PAYROLL = MBackend + "mfinance/payroll";
export const MF_SUBSCRIPTIONS = MBackend + "mfinance/subscriptions";

export const MM_MODULES = MBackend + "mmodules/modules";
