import { MdOutlineArrowForward, MdOutlineArrowBack } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import { Container, Row, Col } from "react-bootstrap";
import { Outlet } from "react-router-dom";
// import Header from "./header";
import MInstSidenav from "./sidenav";
import { Jquery } from "react-jquery-plugin";
import "../style/manage/home.css";
import "../style/sidenav.css";
import { useState } from "react";
import useKeyModel from "../func/keyboard";
import Header from "../component/header/header";
import SidenavMobile from "../component/sidenav/sidenav";

function MInst() {
  const [sidenavMobile, setSidenavMobile] = useState(false);
  const [status, setStatus] = useState(false);
  const [sideNavSize, setSideNavSize] = useState(2);
  const [contSize, setContSize] = useState(10);

  const changeStatus = (v) => {
    if (v === false) {
      setStatus(true);
      setSideNavSize(1);
      setContSize(11);
      Jquery(".mg-cont").css("left", "0");
    } else if (v === true) {
      setStatus(false);
      setSideNavSize(2);
      setContSize(10);
      Jquery(".mg-cont").css("left", "8%");
    }
  };

  useKeyModel(changeStatus, "ArrowRight", "ArrowLeft");

  const handleSidenavMobile = (newState) => {
    setSidenavMobile(newState);
  };

  return (
    <>
      <Header handleSidenavMobile={handleSidenavMobile} />

      {sidenavMobile === true && (
        <div className="fixed top-0 left-0 z-50 bg-gray-50 h-screen !w-72 border">
          <SidenavMobile handleSidenavMobile={handleSidenavMobile} />
        </div>
      )}

      <Container className="mg-container" fluid>
        <Row className="justify-content-center">
          {status === false && (
            <Col
              xl={sideNavSize}
              xxl={sideNavSize}
              className="sidenav"
              id="sidenav"
            >
              <div className="sidenav-inner-bor">
                <MInstSidenav status={status} />
              </div>
            </Col>
          )}
          <Col
            sm={12}
            md={12}
            lg={12}
            xl={contSize}
            xxl={contSize}
            className="mg-cont"
          >
            <Outlet />
          </Col>
        </Row>
      </Container>

      <div className="sidenav-btn">
        <Tooltip title={status === true ? "Arrow Right" : "Arrow Left"}>
          <IconButton
            onClick={() => changeStatus(status)}
            className="sidenav-btn-icon"
            sx={{ mt: 1 }}
          >
            {status === true && (
              <>
                <MdOutlineArrowForward />
              </>
            )}
            {status === false && <MdOutlineArrowBack />}
          </IconButton>
        </Tooltip>
      </div>
    </>
  );
}

export default MInst;
