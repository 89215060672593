
// app
// export const fM     = "https://muqadar.com/"; 

// // // meducation
// export const fMEd   = "https://education.muqadar.com/"; 
// export const fMEdMI = "https://mi.education.muqadar.com/"; 
// export const fMEdMS = "https://ms.education.muqadar.com/"; 
// export const fMEdMSd = "https://msd.education.muqadar.com/"; 
// export const fMEdMP = "https://mp.education.muqadar.com/"; 
// export const fMEdMPI = "https://mpi.education.muqadar.com/"; 
// export const fMEdAdmin  = "https://admin.education.muqadar.com/"; 

// export const fMH    = "https://hostels.muqadar.com/"; 

// db
export const MBackend   = "https://backend.muqadar.com/"; 

// storage
export const MStorage   = "https://storage.muqadar.com/"; 

// app
export const fM         = "http://localhost:3000/"; 
//  meducation 
export const fMEd   = "http://localhost:3011/"; 
export const fMEdMI = "http://localhost:3021/"; 
export const fMEdMS = "http://localhost:3022/"; 
export const fMEdMSd = "http://localhost:3023/"; 
export const fMEdMP = "http://localhost:3024/"; 
export const fMEdMPI = "http://localhost:3025/"; 
export const fMEdAdmin  = "http://localhost:3031/"; 

// export const fMH    = "http://localhost:3022/"; 

// // db
// export const MBackend   = "http://localhost:8000/"; 

// // storage
// export const MStorage   = "http://localhost:3100/"; 