// external
import Select from "@mui/material/Select";
import {
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Divider,
  Card,
  Snackbar,
  Avatar,
  Chip,
} from "@mui/material/";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CgTrashEmpty } from "react-icons/cg";
import { Tooltip as MUITooltip } from "@mui/material/";

// internal
import * as React from "react";
import { useState } from "react";
import { getCookie } from "../../../hooks/useCookie";
import { numberFormate } from "../../../component/number";
import { Col, Row } from "react-bootstrap";
import TotalTb, { TotalTbGraph } from "../../../component/tables/totaltb";
import { CardTwoCol } from "../../../component/cards/cards";
import PageProgress from "../../../component/progress";
import ManageContHead from "../../../component/manage/manageconthead";
import { ME_SD_SD, MF_FEES } from "../../../config/api";
import { FaCheckCircle, FaCircle, FaPencilAlt } from "react-icons/fa";
import { idDecrypt } from "../../../hooks/useEncryption";
import { SD_PROF } from "../../../config/mstorage";
import { FeesContext } from "../../../context/feesContext";
import { SdContext } from "../../../context/sdContext";
import { SystemContext } from "../../../context/systemContext";
import DataTable from "./components/table";
import { getData } from "../../../hooks/useFetch";
import timeAgo from "../../../utils/timeAgo";
import { StatsContext } from "../../../context/statsContext";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

const fetchData = (page, pageSize) => {
  return getData(MF_FEES + "/all", {
    fields:
      "id,mf_receiver,mf_payer,session,status,service,amount,term,time,date",
    column: "mf_receiver",
    value: idDecrypt(getCookie("minst_id")),
    queryFurther: "",
    page,
    pageSize,
  }).then((response) => response);
};

export default function IFFeesTable() {
  const { feesTotalState } = React.useContext(StatsContext);
  const { feesState, addManyFees, deleteFees } = React.useContext(FeesContext);
  const { sdState } = React.useContext(SdContext);
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  // React.useEffect(() => {
  //   getData(MF_FEES + "/all", {
  //     fields:
  //       "id,mf_receiver,mf_payer,session,status,service,amount,term,time,date",
  //     column: "mf_receiver",
  //     value: idDecrypt(getCookie("minst_id")),
  //     queryFurther: "",
  //     page: 1,
  //     pageSize: 20,
  //   }).then((fees) => console.log({ fees }));
  // }, []);

  // const delPayroll = (id) => {
  //   let person = prompt("Please type 'Delete' to Confirm ", "");
  //   if (person === "Delete") {
  //     setProgress(true);

  //     setTimeout(() => {
  //       setProgress(false);
  //       deleteFees(id);
  //     }, 1000);

  //     const xmlhttp = new XMLHttpRequest();
  //     xmlhttp.onload = function () {
  //       setTimeout(() => {
  //         setOpen(true);
  //       }, 1000);
  //     };

  //     xmlhttp.open("DELETE", MF_FEES);
  //     xmlhttp.setRequestHeader("Content-Type", "application/json");

  //     const requestBody = JSON.stringify({ id: id });
  //     xmlhttp.send(requestBody);

  //     setTimeout(() => {
  //       setOpen(false);
  //     }, 2500);
  //   }
  // };
  // ____

  const [fees, setFees] = useState([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(100);

  // React.useEffect(() => {
  //   fetchData(page, pageSize).then((data) => {
  //     setFees(data);
  //     // setTotalCount(data.totalCount); // Assuming your API response includes total count
  //   });
  // }, [page, pageSize]);

  const updateCards = Object.values(feesState).map((key, index) => (
    <div className="m-card-tb-row anim-opacity" id={index}>
      <div className="m-card-tb-col">
        <div className="flex text-center items-center">
          <div className="flex-1 basis-1/4 ">
            <Avatar alt={key["fname"]} src={SD_PROF + key["prof"]} />
          </div>
          <div className="flex-2 basis-3/4 ml-2 text-left">{key["fname"]}</div>
        </div>
      </div>
      <div className="m-card-tb-col">
        <span>{key["service"]}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{key["term"]}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{"Rs." + numberFormate(key["amount"])}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{key["time"]}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{timeAgo(key["date"])}</span>
      </div>
      {/* <div className='m-card-tb-col' style={{textAlign:"right"}}>
        <MUITooltip title="Delete" arrow> 
          <IconButton size='small' 
            onClick={() => delPayroll(parseInt(key['id']))}>
            <CgTrashEmpty />
          </IconButton>
        </MUITooltip>
      </div> */}
    </div>
  ));
  const [currentPage, setCurrentPage] = useState(2);
  const [arrayLen, setArrayLen] = useState(false);

  const showMore = () => {
    if (arrayLen === false) {
      Promise.all([
        getData(MF_FEES + "/all", {
          fields:
            "id,mf_receiver,mf_payer,session,status,service,amount,term,time,date",
          column: "mf_receiver",
          value: idDecrypt(getCookie("minst_id")),
          queryFurther:
            " AND fees.session=" +
            idDecrypt(getCookie("session")) +
            " ORDER BY id DESC",
          page: currentPage,
          pageSize: 5,
        }),
      ])
        .then(([s]) => {
          addManyFees(s);
          setCurrentPage(currentPage + 1);
          if (s.length <= 0) {
            setArrayLen(true);
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  };

  return (
    <div className="mg-cont-m">
      <div className="mx-auto max-w-screen-xl my-2">
        <div className="m-card-tb-row font-bold">
          <div className="m-card-tb-col">Student</div>
          <div className="m-card-tb-col">Service</div>
          <div className="m-card-tb-col">Term</div>
          <div className="m-card-tb-col">Amount</div>
          <div className="m-card-tb-col">Time</div>
          <div className="m-card-tb-col">Added</div>
        </div>
        {updateCards}
        {Object.values(feesState).length < feesTotalState && (
          <div className="fixed text-right left-0 w-full bottom-10  ">
            <div className="  inline-block py-4 px-10 ">
              <button
                onClick={() => showMore()}
                className="border rounded-full px-3  py-2 shadow-md bg-gray-50 hover:bg-gray-100"
              >
                Show More - {feesTotalState - Object.values(feesState).length}
              </button>
            </div>
          </div>
        )}
      </div>

      <Snackbar
        open={open}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Fee Deleted Successfully.
        </Alert>
      </Snackbar>
    </div>
  );
}
