// external
import Select from "@mui/material/Select";
import {
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Divider,
  Card,
  Snackbar,
  Avatar,
} from "@mui/material/";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CgTrashEmpty } from "react-icons/cg";
import { Tooltip as MUITooltip } from "@mui/material/";

// internal
import * as React from "react";
import { useState } from "react";
import { getCookie } from "../../hooks/useCookie";
import { numberFormate } from "../../component/number";
import { Col, Row } from "react-bootstrap";
import TotalTb, { TotalTbGraph } from "../../component/tables/totaltb";
import { CardTwoCol } from "../../component/cards/cards";
import PageProgress from "../../component/progress";
import ManageContHead from "../../component/manage/manageconthead";
import { MF_PAYROLL } from "../../config/api";
import { FaCheckCircle, FaCircle, FaPencilAlt } from "react-icons/fa";
import { idDecrypt } from "../../hooks/useEncryption";
import { PayrollContext } from "../../context/payrollContext";
import { StaffContext } from "../../context/staffContext";
import { STAFF_PROF } from "../../config/mstorage";
import { SystemContext } from "../../context/systemContext";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

export default function MFPayroll() {
  const { systemState } = React.useContext(SystemContext);
  const { payrollState, addPayroll, deletePayroll } =
    React.useContext(PayrollContext);
  const { staffState } = React.useContext(StaffContext);
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [modelShow, setModelShow] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject["mf_employer"] = idDecrypt(getCookie("minst_id"));
    formDataObject["session"] = systemState?.session;

    fetch(MF_PAYROLL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        const upId = data.insertId;
        formDataObject["id"] = upId;
        formDataObject["date"] = new Date();
        addPayroll(formDataObject);

        if (data.affectedRows === 1) {
          setTimeout(() => {
            setProgress(false);
            setResult(true);
          }, 1000);
          setTimeout(() => {
            setModelShow(false);
            setResult(false);
          }, 3000);
        } else {
          console.log("Some error occurred");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const delPayroll = (id) => {
    let person = prompt("Please type 'Delete' to Confirm ", "");
    if (person === "Delete") {
      setProgress(true);

      setTimeout(() => {
        setProgress(false);
        deletePayroll(id);
      }, 1000);

      const xmlhttp = new XMLHttpRequest();
      xmlhttp.onload = function () {
        setTimeout(() => {
          setOpen(true);
        }, 1000);
      };

      xmlhttp.open("DELETE", MF_PAYROLL);
      xmlhttp.setRequestHeader("Content-Type", "application/json");

      const requestBody = JSON.stringify({ id: id });
      xmlhttp.send(requestBody);

      setTimeout(() => {
        setOpen(false);
      }, 2500);
    }
  };
  // ____

  const handleShow = () => setModelShow(true);

  // expenses month loop
  const updateCards = Object.values(payrollState).map((key, index) => (
    <div className="m-card-tb-row anim-opacity" id={index}>
      <div className="m-card-tb-col">
        {Object.values(staffState)
          .filter((sd) => sd.mid == key["mf_employee"])
          .map((filteredSd) => (
            <>
              <Row style={{ alignItems: "center" }}>
                <Col sm={2} md={2} lg={2} xl={2}>
                  <Avatar
                    alt={filteredSd["name"]}
                    src={STAFF_PROF + filteredSd["prof"]}
                  />
                </Col>
                <Col style={{ textAlign: "left" }}>{filteredSd["name"]}</Col>
              </Row>
            </>
          ))}
      </div>
      <div className="m-card-tb-col">
        <span>{key["account"]}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{"Rs." + numberFormate(key["salary"])}</span>
      </div>
      <div className="m-card-tb-col">
        <span>{key["month"]}</span>
      </div>
      <div className="m-card-tb-col" style={{ textAlign: "right" }}>
        <MUITooltip title="Delete" arrow>
          <IconButton
            size="small"
            onClick={() => delPayroll(parseInt(key["id"]))}
          >
            <CgTrashEmpty />
          </IconButton>
        </MUITooltip>
      </div>
    </div>
  ));

  const staff_html = Object.values(staffState).map((key, index) => (
    <MenuItem id={key} value={key["mid"]}>
      <div className="flex items-center">
        <div>
          <Avatar alt={key["name"]} src={STAFF_PROF + key["prof"]} />
        </div>
        <div className="ml-2">
          <b>{key["name"]}</b>- {key["position"]}
        </div>
      </div>
    </MenuItem>
  ));

  function calculateTotalCostByAccount(array, account) {
    let totalCost = 0;
    for (let i = 0; i < array.length; i++) {
      if (array[i].account === account) {
        totalCost += parseInt(array[i].salary);
      }
    }
    return totalCost;
  }

  const tableRows = [
    {
      title: "Teacher",
      amount: calculateTotalCostByAccount(payrollState, "Teacher"),
    },
    {
      title: "Finance",
      amount: calculateTotalCostByAccount(payrollState, "Finance"),
    },
    {
      title: "Security",
      amount: calculateTotalCostByAccount(payrollState, "Security"),
    },
    {
      title: "Admin",
      amount: calculateTotalCostByAccount(payrollState, "Admin"),
    },
    {
      title: "Other",
      amount: calculateTotalCostByAccount(payrollState, "Other"),
    },
  ];

  return (
    <div className="mg-cont-m">
      <PageProgress status={progress} />
      <ManageContHead title={"Payroll"} func={handleShow} />

      <CardTwoCol
        head={"Total Payroll"}
        table={
          <TotalTb
            lefthead={"Accounts"}
            righthead={"Payroll"}
            rows={tableRows}
          />
        }
        graph={<TotalTbGraph head={"Total Payroll"} rows={tableRows} />}
      />

      <Card className="m-card" variant="outlined">
        <div className="m-card-header">
          <Row>
            <Col>
              <h1>Entries</h1>{" "}
            </Col>
            <Col style={{ textAlign: "right" }}></Col>
          </Row>
        </div>
        <div className="m-card-cont">
          <Row>
            <Col className="m-tb-wide">
              <div className="m-tb-wide-cont">
                <div className="m-card-tb-row">
                  <div className="m-card-tb-col" style={{ textAlign: "left" }}>
                    <span>
                      <b>Staff</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col">
                    <span>
                      <b>Account</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col">
                    <span>
                      <b>Amount</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col">
                    <span>
                      <b>Month</b>
                    </span>
                  </div>
                  <div className="m-card-tb-col" style={{ textAlign: "right" }}>
                    <span>
                      <b>Actions</b>
                    </span>
                  </div>
                </div>

                {updateCards}
              </div>
            </Col>
          </Row>
        </div>
      </Card>

      {modelShow ? (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full"
            style={{
              background: "rgba(255, 255, 255, 0.9)",
              backdropFilter: "saturate(180%) blur(5px)",
            }}
            onClick={() => setModelShow(false)}
          ></div>
          <div className="flex items-baseline min-h-screen px-2 py-2 md:!px-4 md:!py-8">
            <div className="relative w-full max-w-xl mx-auto bg-white rounded-lg shadow-md border border-gray-50">
              <div className="flex items-center justify-between px-4 py-3 border-b">
                <h4 className="text-xl font-medium text-gray-800">
                  <div className="flex" style={{ alignItems: "center" }}>
                    Add Payroll
                  </div>
                </h4>
                <button
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100"
                  onClick={() => setModelShow(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <form
                action={MF_PAYROLL}
                method="POST"
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className="space-y-2 p-4 text-[15.5px] leading-relaxed text-gray-500">
                  {/* account */}
                  <FormControl
                    className="mg-modal-in"
                    variant="outlined"
                    sx={{ textAlign: "left" }}
                    fullWidth
                    required
                  >
                    <InputLabel id="account">Account</InputLabel>
                    <Select
                      labelId="account"
                      id="account"
                      label="type"
                      defaultValue=""
                      name="account"
                    >
                      <MenuItem value={"Teacher"}>Teacher</MenuItem>
                      <MenuItem value={"Finance"}>Finance</MenuItem>
                      <MenuItem value={"Security"}>Security</MenuItem>
                      <MenuItem value={"Admin"}>Admin</MenuItem>
                      <MenuItem value={"Other"}>Other</MenuItem>
                    </Select>
                  </FormControl>

                  {/* staff */}
                  <FormControl
                    className="mg-modal-in"
                    variant="outlined"
                    sx={{ textAlign: "left" }}
                    fullWidth
                    required
                  >
                    <InputLabel id="mf_employee">Staff</InputLabel>
                    <Select
                      labelId="mf_employee"
                      id="mf_employee"
                      label="type"
                      defaultValue=""
                      name="mf_employee"
                    >
                      {staff_html}
                    </Select>
                  </FormControl>

                  <Divider style={{ margin: "5px 0px" }} />

                  {/* Category */}
                  <FormControl
                    className="mg-modal-in"
                    variant="outlined"
                    sx={{ textAlign: "left" }}
                    fullWidth
                    required
                  >
                    <InputLabel id="category">Month</InputLabel>
                    <Select
                      labelId="month"
                      id="month"
                      defaultValue=""
                      label="Month"
                      name="month"
                    >
                      <MenuItem value={"January"}>January</MenuItem>
                      <MenuItem value={"Feburary"}>Feburary</MenuItem>
                      <MenuItem value={"March"}>March</MenuItem>
                      <MenuItem value={"April"}>April</MenuItem>
                      <MenuItem value={"May"}>May</MenuItem>
                      <MenuItem value={"June"}>June</MenuItem>
                      <MenuItem value={"July"}>July</MenuItem>
                      <MenuItem value={"August"}>August</MenuItem>
                      <MenuItem value={"September"}>September</MenuItem>
                      <MenuItem value={"October"}>October</MenuItem>
                      <MenuItem value={"November"}>November</MenuItem>
                      <MenuItem value={"December"}>December</MenuItem>
                    </Select>
                  </FormControl>

                  <Divider style={{ margin: "5px 0px" }} />

                  {/* salary */}
                  <TextField
                    id="salary"
                    name="salary"
                    multiline
                    label="Amount"
                    rows={1}
                    fullWidth
                    variant="outlined"
                    required
                  />

                  <Divider style={{ margin: "5px 0px" }} />

                  {result === true && (
                    <Alert severity="success">Salary Added Successfully.</Alert>
                  )}
                </div>

                <div className="text-right items-center px-4 py-3 border-t">
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    disableElevation={true}
                    className="prime-btn !rounded-md"
                  >
                    {progress === true && result === false ? (
                      <FaCircle className="mr-2" /> // Display a circle icon for "Editing in progress"
                    ) : result === true ? (
                      <FaCheckCircle className="mr-2" /> // Display a checkmark icon for "Message Edited"
                    ) : (
                      <FaPencilAlt className="mr-2" /> // Display a pencil icon for "Edit Message" (you can replace this with an appropriate edit icon)
                    )}
                    {progress === true && result === false
                      ? "Adding"
                      : result === true
                      ? "Payroll Added"
                      : "Add Payroll"}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <Snackbar
        open={open}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Payroll Deleted Successfully.
        </Alert>
      </Snackbar>
    </div>
  );
}
