// external
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import { CssBaseline, Tooltip } from "@mui/material";
import { FiMenu } from "react-icons/fi";

// internal
import * as React from "react";
import { Link } from "react-router-dom";
import "./header.css";
import { UserContext } from "../../context/userContext";
import { INST_PROF, MACCOUNT_PROF } from "../../config/mstorage";
import { useState } from "react";
import { MdOutlineLogout } from "react-icons/md";

const Header = ({ handleSidenavMobile }) => {
  // login checkup
  const { user } = React.useContext(UserContext);
  const [modelView, setModelView] = useState(false);

  // style
  const headToolbar = {
    minHeight: "50px",
  };

  return (
    <>
      <div className="m-mg-head border-b block md:hidden">
        <div className="flex items-center">
          <div className="flex-1">
            <IconButton
              className="!ml-2"
              onClick={() => handleSidenavMobile(true)}
            >
              <FiMenu />
            </IconButton>
          </div>
          <div className="flex-1 text-center">
            <div className="inline-block p-1">
              <Link to={"./"}>
                <IconButton
                  sx={{ p: 0, mr: 1 }}
                  style={{ transform: "scale(0.9)" }}
                >
                  <Avatar
                    alt={user["instData"].name}
                    src={INST_PROF + user["instData"].profile}
                  />
                </IconButton>
              </Link>
            </div>
          </div>
          <div className="flex-1 text-right">
            <span
              className="text-blue-500 font-black text-xl cursor-pointer px-3 rounded-full"
              onClick={() => setModelView(true)}
            >
              iF
            </span>
          </div>
        </div>
      </div>
      <CssBaseline>
        <AppBar
          position="static"
          className="m-mg-head bg-white !hidden md:!block "
          style={{ boxShadow: "none" }}
        >
          <Container
            maxWidth="xl"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <Toolbar style={headToolbar}>
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Typography variant="h6" style={{ display: "inline-block" }}>
                  <Link to={"./"} style={{ textDecoration: "none !important" }}>
                    <IconButton
                      sx={{ p: 0, mr: 1 }}
                      style={{ transform: "scale(0.9)" }}
                    >
                      <Avatar
                        alt={user["instData"].name}
                        src={INST_PROF + user["instData"].profile}
                      />
                    </IconButton>
                  </Link>
                </Typography>

                <Typography
                  variant="h6"
                  noWrap
                  style={{ display: "inline-block" }}
                  sx={{
                    m: 0,
                    fontFamily: '"Noto Sans", sans-serif',
                    fontWeight: 300,
                    fontSize: "18px",
                    color: "black",
                    textDecoration: "none",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                  }}
                >
                  <span className="">{user["instData"].name}</span>
                </Typography>
              </Box>

              <Box sx={{ flexGrow: 1 }}></Box>

              <Box sx={{ flexGrow: 1 }} style={{ textAlign: "right" }}>
                <span
                  className="text-blue-500 font-black text-2xl cursor-pointer px-3 pb-1 "
                  onClick={() => setModelView(true)}
                >
                  iFinance
                </span>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </CssBaseline>

      {modelView ? (
        <div className="fixed inset-0 z-[100] overflow-y-auto">
          <div
            className="fixed inset-0 w-full h-full"
            onClick={() => setModelView(false)}
          ></div>
          <div className="flex items-baseline text-right min-h-screen px-2 py-2 md:!pr-20 md:!pt-14">
            <div className="relative w-full max-w-sm ml-auto bg-white text-right rounded-md shadow-md border ">
              <div className="flex items-center justify-between p-2 border-b ">
                <h4 className="text-blue-500 font-black text-2xl px-3 pb-1 rounded-full">
                  iFinance
                </h4>
                <button
                  className="p-2 text-gray-400 rounded-md hover:bg-gray-100 "
                  onClick={() => setModelView(false)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5 mx-auto"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              </div>

              <div className="space-y-2 px-2 text-left text-gray-500 ">
                <div className="py-3">
                  <h1 className="mb-4 px-2">
                    iFinance manages income, expenses, payroll and fees.
                  </h1>

                  <h2 className="mb-2 px-2">MEducation</h2>
                  <div className="masonry-grid masonry-grid-count-1">
                    <div className="masonry-grid__item text-left hover:shadow-sm border ">
                      <a
                        href="https://meducation.pk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1 className="font-black prime-color text-xl">
                          MEducation
                        </h1>
                      </a>
                    </div>
                    <div className="masonry-grid__item text-left  hover:shadow-sm border ">
                      <a
                        href="https://iad.meducation.pk"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <h1 className="font-black prime-color text-xl">
                          iAdmin
                        </h1>
                      </a>
                    </div>
                  </div>
                  <h2 className="mb-2 px-2">Account</h2>
                  <div className="masonry-grid masonry-grid-count-1">
                    <div className="masonry-grid__item text-left  hover:shadow-sm border   ">
                      <div className="flex items-center space-x-2">
                        <Avatar
                          className="!w-8 !h-8 !text-base"
                          src={MACCOUNT_PROF + user["maccount"].profile}
                          alt={user["maccount"].maccount}
                        />
                        <span>{user["maccount"].maccount}</span>
                      </div>
                    </div>
                    <div className="masonry-grid__item text-left  hover:shadow-sm border   ">
                      <a
                        href="https://meducation.pk/logout"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="flex items-center space-x-2 py-1">
                          <MdOutlineLogout style={{ fontSize: "23px" }} />
                          <span>Logout</span>
                        </div>
                      </a>
                    </div>
                  </div>

                  <div className="relative text-xs mx-2 bottom-0 md:left-1/2 md:transform md:-translate-x-1/2">
                    © {new Date().getFullYear()} MEducation, All rights
                    reserved.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Header;
