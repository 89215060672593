// external
import Select from "@mui/material/Select";
import {
  IconButton,
  Alert,
  InputLabel,
  MenuItem,
  Button,
  TextField,
  FormControl,
  Divider,
  Card,
  Snackbar,
  Avatar,
  Chip,
  Paper,
  Typography,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  InputAdornment,
} from "@mui/material/";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { CgTrashEmpty } from "react-icons/cg";
import { Tooltip as MUITooltip } from "@mui/material/";

// internal
import * as React from "react";
import { useState } from "react";
import { getCookie } from "../../../hooks/useCookie";
import { numberFormate } from "../../../component/number";
import { Col, Row, Table } from "react-bootstrap";
import TotalTb, { TotalTbGraph } from "../../../component/tables/totaltb";
import { CardTwoCol } from "../../../component/cards/cards";
import PageProgress from "../../../component/progress";
import ManageContHead from "../../../component/manage/manageconthead";
import { ME_SD_SD, MF_FEES } from "../../../config/api";
import { FaCheckCircle, FaCircle, FaPencilAlt } from "react-icons/fa";
import { idDecrypt } from "../../../hooks/useEncryption";
import { SD_PROF } from "../../../config/mstorage";
import { FeesContext } from "../../../context/feesContext";
import { SdContext } from "../../../context/sdContext";
import { SystemContext } from "../../../context/systemContext";
import { getData } from "../../../hooks/useFetch";
import { getCustomData } from "../../../hooks/getCustomData";
import ReactToPrint from "react-to-print";
import ReceiptPrint from "./components/Receipt";
import "./components/Receipt/print.css";
import { useLocation } from "react-router-dom";

// graph data
ChartJS.register(ArcElement, Tooltip, Legend);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function IFFeesPay({ feeId }) {
  const { systemState } = React.useContext(SystemContext);
  const { feesState, addFees, deleteFees } = React.useContext(FeesContext);
  const { sdState } = React.useContext(SdContext);
  const [fee, setFee] = useState([]);
  const [sd, setSd] = useState({});
  const [progress, setProgress] = useState(false);
  const [result, setResult] = useState(false);
  const [open, setOpen] = useState(false);
  const query = useQuery();
  const [id, setId] = useState(query.get("id"));

  const [service, setService] = useState("");
  const [term, setTerm] = useState("");
  const [time, setTime] = useState("");
  const [amount, setAmount] = useState("");
  const componentRef = React.useRef();

  React.useEffect(() => {
    // const idFromQuery = query.get("id");
    if (id) {
      // setId(id);
      Promise.all([
        getCustomData(
          MF_FEES + "/custom",
          { id: parseInt(id) },
          " ORDER BY fees.id"
        ),
      ])
        .then(([f]) => {
          setFee(f[0]);
          setService(f[0]?.service);
          setTerm(f[0]?.term);
          setTime(f[0]?.time);
          setAmount(f[0]?.amount);

          getCustomData(
            ME_SD_SD + "/custom",
            { msd_id: f[0]?.mf_payer },
            " ORDER BY id"
          ).then((data) => setSd(data[0]));
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }
  }, []);

  const handleService = (event) => {
    setService(event.target.value);
  };

  const handleTerm = (event) => {
    setTerm(event.target.value);
  };

  const handleTime = (event) => {
    setTime(event.target.value);
  };

  const handleAmount = (event) => {
    setAmount(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    const form = e.target;
    const formDataObject = {};
    const formElements = form.elements;
    for (let i = 0; i < formElements.length; i++) {
      const input = formElements[i];
      if (input.name) {
        formDataObject[input.name] = input.value;
      }
    }
    formDataObject["id"] = parseInt(feeId);
    formDataObject["key"] = "status";
    formDataObject["value"] = "Paid";

    fetch(MF_FEES, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formDataObject),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Error: " + response.status);
        }
      })
      .then((data) => {
        // const upId = data.insertId;
        // formDataObject["id"] = upId;
        // formDataObject["date"] = new Date();
        // addFees(formDataObject);

        if (data.affectedRows === 1) {
          setTimeout(() => {
            setProgress(false);
            setResult(true);
          }, 1000);
          setTimeout(() => {
            setResult(false);
          }, 3000);
        } else {
          console.log("Some error occurred");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [searchValue, setSearchValue] = useState("");

  const handleSearchValue = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSubmitNumber = () => {
    getData(MF_FEES, {
      column: "id",
      value: searchValue,
    }).then((data) => {
      setId(searchValue);
      setFee(data[0]);
      setService(data[0]?.service);
      setTerm(data[0]?.term);
      setTime(data[0]?.time);
      setAmount(data[0]?.amount);

      getCustomData(
        ME_SD_SD + "/custom",
        { msd_id: data[0]?.mf_payer },
        " ORDER BY id"
      ).then((data) => setSd(data[0]));
    });
  };

  const handleBeforePrint = () => {
    document.title = sd?.fname; // Set the document title to the desired PDF filename
  };

  const handleAfterPrint = () => {
    document.title = sd?.fname; // Revert the document title to its original value
  };
  console.log(id);
  return (
    <div className="mg-cont-m">
      <PageProgress status={progress} />

      {!id && (
        <>
          <div className="max-w-4xl py-4 mx-auto ">
            <div className="relative">
              <input
                type="text"
                className="border !border-blue-400 bg-blue-50 focus:outline-blue-500 text-blue-500 rounded-full py-3 px-3 w-full"
                placeholder="Type Fee Number"
                autoFocus
                value={searchValue}
                onChange={(event) => handleSearchValue(event)}
              />
              <div className="text-right py-2 px-3">
                <button
                  onClick={handleSubmitNumber}
                  className={`px-3 py-2 text-sm text-center border !border-blue-500 inline-flex items-center duration-150 text-blue-500 bg-blue-100 rounded-full hover:bg-blue-200 active:bg-blue-300`}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {id && (
        <div className="max-w-3xl mx-auto">
          {fee?.status == "Unpaid" && (
            <form
              action={MF_FEES}
              method="POST"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="space-y-2 p-4 text-[15.5px] leading-relaxed text-gray-500">
                <div className="px-3 py-3 mb-2 border-b">
                  <div className="flex items-center">
                    <div className="inline-block">
                      <Avatar alt={sd?.fname} src={SD_PROF + sd?.prof} />
                    </div>
                    <div className="ml-2 text-left ">
                      {sd?.fname} {sd?.lname}
                    </div>
                  </div>
                </div>
                <FormControl
                  className="mg-modal-in"
                  variant="outlined"
                  sx={{ textAlign: "left" }}
                  fullWidth
                  required
                >
                  <InputLabel id="service">Fee Type</InputLabel>
                  <Select
                    labelId="service"
                    id="service"
                    defaultValue=""
                    value={service}
                    label="service"
                    name="service"
                    onChange={(event) => handleService(event)}
                  >
                    <MenuItem value={"Tuition"}>Tuition</MenuItem>
                    <MenuItem value={"Admission"}>Admission</MenuItem>
                    <MenuItem value={"Service"}>Service</MenuItem>
                    <MenuItem value={"Sports"}>Sports</MenuItem>
                    <MenuItem value={"Health"}>Health</MenuItem>
                    <MenuItem value={"Other"}>Other</MenuItem>
                  </Select>
                </FormControl>

                {service === "Tuition" && (
                  <>
                    <Divider style={{ margin: "5px 0px" }} />

                    <FormControl
                      className="mg-modal-in"
                      variant="outlined"
                      sx={{ textAlign: "left" }}
                      fullWidth
                      required
                    >
                      <InputLabel id="term">Term</InputLabel>
                      <Select
                        labelId="term"
                        id="term"
                        defaultValue=""
                        label="term"
                        name="term"
                        value={term}
                        onChange={(event) => handleTerm(event)}
                      >
                        <MenuItem value={"None"}>None</MenuItem>
                        <MenuItem value={"Month"}>Month</MenuItem>
                        <MenuItem value={"Quarter"}>Quarter</MenuItem>
                        <MenuItem value={"Semester"}>Semester</MenuItem>
                        <MenuItem value={"Session"}>Session</MenuItem>
                      </Select>
                    </FormControl>

                    <Divider style={{ margin: "5px 0px" }} />

                    <FormControl
                      className="mg-modal-in"
                      variant="outlined"
                      sx={{ textAlign: "left" }}
                      fullWidth
                      required
                    >
                      <InputLabel id="time">
                        {term === "Month" && <>Month</>}
                        {term === "Quarter" && <>Quarter</>}
                        {term === "Semester" && <>Semester</>}
                        {term === "Session" && <>Session</>}
                      </InputLabel>
                      <Select
                        labelId="time"
                        id="time"
                        defaultValue=""
                        label="time"
                        name="time"
                        value={time}
                        onChange={(event) => handleTime(event)}
                      >
                        <Divider textAlign="left" sx={{ m: 2 }}>
                          <Chip label="Month" />
                        </Divider>
                        <MenuItem value={"January"}>January</MenuItem>
                        <MenuItem value={"Feburary"}>Feburary</MenuItem>
                        <MenuItem value={"March"}>March</MenuItem>
                        <MenuItem value={"April"}>April</MenuItem>
                        <MenuItem value={"May"}>May</MenuItem>
                        <MenuItem value={"June"}>June</MenuItem>
                        <MenuItem value={"July"}>July</MenuItem>
                        <MenuItem value={"August"}>August</MenuItem>
                        <MenuItem value={"September"}>September</MenuItem>
                        <MenuItem value={"October"}>October</MenuItem>
                        <MenuItem value={"November"}>November</MenuItem>
                        <MenuItem value={"December"}>December</MenuItem>
                        <Divider textAlign="left" sx={{ m: 2 }}>
                          <Chip label="Quarter" />
                        </Divider>
                        <MenuItem value={"First"}>First</MenuItem>
                        <MenuItem value={"Second"}>Second</MenuItem>
                        <MenuItem value={"Third"}>Third</MenuItem>
                        <MenuItem value={"Fourth"}>Fourth</MenuItem>
                        <Divider textAlign="left" sx={{ m: 2 }}>
                          <Chip label="Semester" />
                        </Divider>
                        <MenuItem value={"First"}>First</MenuItem>
                        <MenuItem value={"Second"}>Second</MenuItem>
                        <MenuItem value={"Third"}>Third</MenuItem>
                        <MenuItem value={"Fifth"}>Fifth</MenuItem>
                        <MenuItem value={"Sixth"}>Sixth</MenuItem>
                        <MenuItem value={"Seventh"}>Seventh</MenuItem>
                        <MenuItem value={"Eighth"}>Eighth</MenuItem>
                        <Divider textAlign="left" sx={{ m: 2 }}>
                          <Chip label="Session" />
                        </Divider>
                        <MenuItem value={"22-23"}>22-23</MenuItem>
                        <MenuItem value={"23-24"}>23-24</MenuItem>
                      </Select>
                    </FormControl>
                  </>
                )}
                <Divider style={{ margin: "5px 0px" }} />

                <TextField
                  id="amount"
                  name="amount"
                  multiline
                  label="Amount"
                  value={amount}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">Rs</InputAdornment>
                    ),
                  }}
                  onChange={(event) => handleAmount(event)}
                  rows={1}
                  required
                  fullWidth
                  variant="outlined"
                />

                <Divider style={{ margin: "5px 0px" }} />

                {result === true && (
                  <Alert severity="success">Fee Added Successfully.</Alert>
                )}
              </div>

              <div className="text-right items-center px-4 py-3 border-t">
                <div className="flex mt-4 space-x-2 flex-end items-center text-right justify-end">
                  <div className="text-right">
                    <button
                      onClick={() => setId(null)}
                      className={`px-2 py-1 text-sm text-center border !border-blue-500 inline-flex items-center duration-150 text-blue-500 bg-blue-100 rounded-lg hover:bg-blue-200 active:bg-blue-300`}
                    >
                      Cancel
                    </button>
                  </div>
                  <div>
                    <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      disableElevation={true}
                      className="prime-btn !rounded-md"
                    >
                      Pay
                    </Button>
                  </div>
                </div>
              </div>
            </form>
          )}
          {fee?.status == "Paid" && (
            <>
              <Receipt fee={fee} setFee={setFee} sd={sd} />
              <div className="">
                <ReactToPrint
                  trigger={() => (
                    <button className="border rounded-full px-3 py-2 bg-green-50 text-green-600">
                      Receipt
                    </button>
                  )}
                  content={() => componentRef.current}
                  onBeforeGetContent={handleBeforePrint}
                  onAfterPrint={handleAfterPrint}
                />
              </div>
              <div className="printable-content">
                <ReceiptPrint ref={componentRef} data={fee ? fee : []} />
              </div>
            </>
          )}
        </div>
      )}

      <Snackbar
        open={open}
        autoHideDuration={1000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          Fee Deleted Successfully.
        </Alert>
      </Snackbar>
    </div>
  );
}

const Receipt = ({ fee, setFee, sd }) => {
  return (
    <>
      <div className="bg-white px-6 max-w-md mx-auto rounded-lg">
        <div
          class="p-2 text-sm text-blue-800 rounded-lg bg-blue-50"
          role="alert"
        >
          Fee has been Paid
        </div>

        <div className="border p-4 mt-2 mb-4 rounded-lg shadow-sm">
          <h2 className="text-lg font-bold mb-2">Receipt</h2>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">ID:</span>
            <span className="text-base">{fee?.id}</span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Status:</span>
            <span className="text-base">
              {fee.status == "Paid" && (
                <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-green-900 dark:text-green-300">
                  Paid
                </span>
              )}
              {fee.status == "Unpaid" && (
                <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-red-900 dark:text-red-300">
                  Unpaid
                </span>
              )}
            </span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Service:</span>
            <span className="text-base">{fee?.service}</span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Amount:</span>
            <span className="text-base">Rs.{numberFormate(fee?.amount)}</span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Term:</span>
            <span className="text-base">{fee?.term}</span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Time:</span>
            <span className="text-base">{fee?.time}</span>
          </div>

          <div className="flex justify-between mb-2">
            <span className="text-base font-semibold">Date:</span>
            <span className="text-base">
              {new Date(fee?.date).toLocaleDateString()}
            </span>
          </div>
        </div>

        <div className="border p-4 mt-2 mb-4 rounded-lg shadow-sm">
          <h2 className="text-lg font-bold mb-2">Student</h2>
          <div className="">
            <div className="flex items-center">
              <div className="inline-block">
                <Avatar alt={sd?.fname} src={SD_PROF + sd?.prof} />
              </div>
              <div className="ml-2 text-left ">
                {sd?.fname} {sd?.lname}
              </div>
            </div>
          </div>
        </div>

        <div className="flex mt-4 space-x-2 flex-end items-center text-right justify-end">
          <div className="text-right">
            <button
              onClick={() => setFee()}
              className={`px-2 py-1 text-sm text-center border !border-blue-500 inline-flex items-center duration-150 text-blue-500 bg-blue-100 rounded-full hover:bg-blue-200 active:bg-blue-300`}
            >
              Search Another
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
